import React from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { Header } from "components/Header";
import { QuickReportCard } from "components/Cards/QuickReportCard";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";

export function QuickReports() {

    const navigate = useNavigate()

    return (
        <View style={styles.container}>
            <Header
            title={'Quick Reports'}
            />
                <View style={styles.body}>
                    <PrimaryText fontSize={20} fontWeight={400} >Transactions</PrimaryText>
                    <QuickReportCard
                    title={'History'}
                    onPress={() => navigate(Links.History)}
                    />
                    <QuickReportCard
                    title={'Delivered Items'}
                    onPress={() => navigate(Links.Delivered)}
                    />
                    <QuickReportCard
                    title={'Returned Items'}
                    onPress={() => navigate(Links.Returned)}
                    />

                    <PrimaryText fontSize={20} fontWeight={400} style={{marginTop: 12}} >Financial</PrimaryText>
                    <QuickReportCard
                    title={'Aging Items'}
                    onPress={() => navigate(Links.AgingItems)}
                    />
                    <QuickReportCard
                    title={'Value By Location'}
                    onPress={() => navigate(Links.ValueByLocation)}
                    />
                    <QuickReportCard
                    title={'Most Valuable Items'}
                    onPress={() => navigate(Links.MostValuableItems)}
                    />
                    {/* <QuickReportCard
                    title={'Most Used Items'}
                    onPress={null}
                    /> */}
                    <PrimaryText fontSize={20} fontWeight={400} style={{marginTop: 12}} >Misc.</PrimaryText>
                    <QuickReportCard
                    title={'Open Purchase Orders'}
                    onPress={() => navigate(Links.OpenPurchaseOrders)}
                    />
                    <QuickReportCard
                    title={'B-Stock Items'}
                    onPress={() => navigate(Links.BStock)}
                    />
                    
                </View>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1, 
        width: '50%',
        // borderRightWidth: 1, 
        // borderColor: Colors.primary.gray,
        alignItems: 'center'
    },


});
