import React, { forwardRef, useEffect, useState } from "react"
import {
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  FlatList,
  Switch,
} from "react-native"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { Colors } from "constants/Colors"
import { Header } from "components/Header"
import { CustomInput } from "components/Inputs/CustomInput"
import { useCompany } from "context/CompanyContext"
import { PrimaryText } from "components/Common/PrimaryText"
import { Fonts } from "constants/Fonts"
import "react-datepicker/dist/react-datepicker.css"
import { getCompanyHistoryByDate } from "services/company-service"
import { ApprovalsCard } from "components/Cards/ApprovalsCard"
import { approveQbAdjustment } from "services/quickbooks-service"
import { useParts } from "context/PartsContext"
import { ConfirmModal } from "components/Modals/Confirmation"
import { cloneDeep } from "lodash"
import { rejectTransaction } from "services/transactions-service"
import { formatApprovalsReport } from "utils/exports-helper"
import { approveDToolsAdjustment } from "services/dtools-service"
import { ListContainer } from "components/ListContainer"

const { width } = Dimensions.get("window")

const options = [
  { value: "unapproved", label: "Unapproved" },
  { value: "approved", label: "Approved" },
  { value: "all", label: "All" },
]

export function Approvals() {
  const { company, isDTools, users } = useCompany()
  const { parts, locationParts, manufacturers } = useParts()

  const [search, setSearch] = useState(null)

  const [historyItems, setHistoryItems] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState(options[0])
  const [filtered, setFiltered] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showRejected, setShowRejected] = useState(false)

  const [selectedItem, setSelectedItem] = useState(null)

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false)
  const [confirmationInfo, setConfirmationInfo] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let start = new Date()
    start.setDate(start.getDate() - 7)
    setStartDate(start)
  }, [])

  useEffect(() => {
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const subscriber = getCompanyHistoryByDate(
      company,
      startDate,
      endDate
    ).onSnapshot(
      (querySnapshot) => {
        const temp = []
        querySnapshot.forEach((doc) => {
          const history = {
            id: doc.id,
            ...doc.data(),
          }
          temp.push(history)
        })

        setHistoryItems(temp)
        // console.log('here', temp.length)

        loading && setLoading(false)
      },
      (error) => console.log("error getting approvals", error)
    )

    return () => subscriber()
  }, [startDate, endDate])

  //on search change
  useEffect(() => {
    if (!historyItems) return
    let temp = historyItems
    if (search) {
      temp = historyItems.filter(
        (x) =>
          x.to.toLowerCase().includes(search.toLowerCase()) ||
          x.from.toLowerCase().includes(search.toLowerCase()) ||
          x.model.toLowerCase().includes(search.toLowerCase()) ||
          x.manufacturer.toLowerCase().includes(search.toLowerCase())
      )
      if (temp && !showRejected) {
        temp = temp.filter((x) => !x.rejected)
      }

      setFiltered(temp.filter((x) => x.manufacturer || x.model))
    } else {
      temp = historyItems
      if (temp && !showRejected) {
        temp = temp.filter((x) => !x.rejected)
      }
    }

    if (selectedFilter.value !== "all") {
      temp = temp.filter((x) =>
        selectedFilter.value === "approved" ? x.approved : !x.approved
      )
    }
    temp = temp.filter(
      (x) => !x.manualAdjustment && x.from !== "Staging" && x.to !== "Staging"
    )
    // console.log('here3', temp.length)
    setFiltered(temp.filter((x) => x.manufacturer || x.model))
  }, [search, historyItems, showRejected, selectedFilter])

  function approveItem(item) {
    // console.log("approve item", item)
    // return;
    const cloned = cloneDeep(item)
    setSelectedItem(cloned)

    //quickbooks
    if (!isDTools) {
      if (item.approved && item.synced) {
        // already approved and sync - warn them
        setConfirmationInfo({
          title: "This transaction has already been synced to QuickBooks",
          message:
            "Are you sure you want to unapprove this transaction? If it is approved again, it will be synced to QuickBooks again.",
        })
        setShowConfirmation(true)
      } else {
        approveQbConfirmed(item)
      }
    } else {
      //D-Tools
      if (item.approved && item.synced) {
        setConfirmationInfo({
          title: "This transaction has already been synced to D-Tools",
          message:
            "Are you sure you want to unapprove this transaction? If it is approved again, it will be synced to D-Tools again.",
        })
        setShowConfirmation(true)
      } else {
        approveDToolsConfirmed(item)
      }
    }
  }

  function approveQbConfirmed(item) {
    setShowConfirmation(false)
    approveQbAdjustment(company, item ? item : selectedItem, parts)
  }

  function approveDToolsConfirmed(item) {
    setShowConfirmation(false)
    approveDToolsAdjustment(company, item ? item : selectedItem, parts, manufacturers, users)
  }

  function onRejectPressed(item) {
    console.log("reject item", item)
    const cloned = cloneDeep(item)
    setSelectedItem(cloned)

    if (!item.rejected) {
      // already approved and sync - warn them
      setConfirmationInfo({
        title: "Reject Transaction",
        message:
          "Are you sure you want to reject this transaction? Inventory will be adjusted to it's previous state.",
      })
    } else {
      setConfirmationInfo({
        title: "Undo Reject Transaction",
        message:
          "Are you sure you want to undo this rejected Transaction? Inventory will be adjusted to it's previous state.",
      })
    }
    setShowRejectConfirmation(true)
  }

  function rejectConfirmed() {
    setShowRejectConfirmation(false)
    rejectTransaction(company, parts, selectedItem, locationParts)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  }

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input"
      style={{
        backgroundColor: Colors.primary.white,
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: Colors.primary.lightGray,
        fontFamily: Fonts.primary.regular,
        fontSize: 14,
      }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ))

  function RenderItem({index, style}) {
    const item = filtered[index]
    return (
      <View style={style}>
        <ApprovalsCard
          item={item}
          onPress={() => setSelectedItem(item)}
          showItem
          onApprove={() => approveItem(item)}
          onReject={() => onRejectPressed(item)}
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <Header
        title={"Approval Report"}
        exportData={formatApprovalsReport(filtered, users, selectedFilter)}
      />
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomInput
          placeholder={"Filter By Customer or Item"}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />
        <View style={styles.optionContainer}>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
            numberOfLines={1}
          >
            Date Range:
          </PrimaryText>

          <View style={{ zIndex: 999 }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<DateInput />}
            />
          </View>

          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
          >
            -
          </PrimaryText>

          <View>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<DateInput />}
            />
          </View>
        </View>
      </View>

      <View
        style={{
          zIndex: 998,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <View style={styles.optionContainer}>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
          >
            Approval Status:
          </PrimaryText>
          <Select
            styles={customStyles}
            value={selectedFilter}
            onChange={(value) => setSelectedFilter(value)}
            options={options}
          />
        </View>

        <View style={styles.optionContainer}>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
          >
            Show Rejected:
          </PrimaryText>
          <Switch
            value={showRejected}
            onValueChange={(value) => setShowRejected(value)}
          />
        </View>
      </View>

      {filtered ? (
        <View style={styles.body}>
          <ListContainer
          items={filtered}
          size={100}
          renderItem={RenderItem}
          />
        </View>
      ) : (
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {`No items for approval`}
          </PrimaryText>
        </View>
      )}

      {/** CONFIRM APPROVAL CHANGE */}
      {showConfirmation && (
        <ConfirmModal
          title={confirmationInfo?.title}
          message={confirmationInfo?.message}
          onConfirm={() =>
            isDTools ? approveDToolsConfirmed() : approveQbConfirmed()
          }
          onCancel={() => setShowConfirmation(false)}
        />
      )}

      {/** CONFIRM REJECT CHANGE */}
      {showRejectConfirmation && (
        <ConfirmModal
          title={confirmationInfo?.title}
          message={confirmationInfo?.message}
          onConfirm={() => rejectConfirmed()}
          onCancel={() => setShowRejectConfirmation(false)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  buttonContainer: {
    alignItems: "center",
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  filterContainer: {
    width: "100%",
    // height: height*0.5,
    paddingVertical: 12,
    backgroundColor: Colors.primary.white,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 999,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: Colors.primary.lightGray
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
  },
  dateButton: {
    backgroundColor: Colors.primary.white,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: Colors.primary.lightGray,
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    // marginHorizontal: 6,
    width: 60,
    textAlign: "right",
    color: Colors.primary.white,
  },

  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: "center",
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4,
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  },
})
