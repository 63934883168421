import React from "react";
import { Dimensions, TouchableOpacity, View, Text, StyleSheet } from "react-native";
import {Colors} from "~/constants/Colors";
import * as MaterialCommunityIcons from "react-icons/md";
import * as GoIcons from "react-icons/go";
import { PrimaryText } from "./Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { ExportToCsv } from 'export-to-csv';


const {width, height} = Dimensions.get("window");

export function Header(props) {

  const { 
    title, 
    onBack, 
    backText, 
    onRight, 
    rightText, 
    rightDisabled,
    exportData
  } = props

  const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    filename: `${title} ${new Date().toLocaleDateString()}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  function exportCSV() {
    exportData && exportData?.length > 0 && csvExporter.generateCsv(exportData)
  }

  return (
    <View style={styles.container}>

      { onBack ? 
        <TouchableOpacity 
        onPress={onBack}
        // style={{flex: 1, justifyContent:'center', paddingLeft: 4, opacity: rightDisabled ? 0.4 : 1}}
        >
          {backText ? (
            <Text style={{ fontSize: 20 }}>{backText}</Text>
          ) : (
            <View style={{backgroundColor: Colors.primary.red, borderRadius: 4, justifyContent:'center', alignItems:'center', height: 30, width: 30}}>
              <MaterialCommunityIcons.MdChevronLeft color={Colors.primary.white} size={30} />
            </View>
          )}
        </TouchableOpacity>
        :
        <View style={{width: 40}} />
      }

      <View style={{borderBottomWidth: Colors.primary.darkGray, borderBottomWidth: 0.7, paddingHorizontal: 10, paddingBottom: 4, flexShrink: 1}}>
        <PrimaryText fontSize={16} fontWeight={700} style={{ color: Colors.primary.darkGray, textAlign: 'center', flex: 1 }} numberOfLines={1}>{title}</PrimaryText>
      </View>

      {!!onRight ?
      <TouchableOpacity 
      disabled={rightDisabled}
      onPress={onRight}
      style={[rightText==='Approve All' && {backgroundColor:Colors.primary.lightBlue, padding: 8, borderRadius: 4}, exportData && {marginRight: 54}]}
      >
        {rightText ? (
          <View style={{flexDirection:'row', alignItems:'center', opacity: rightDisabled ? 0.4 : 1}}>
            <PrimaryText fontSize={16} fontWeight={400} style={{ color: rightText==='Approve All' ? Colors.primary.white : Colors.primary.red }}>{rightText}</PrimaryText>
            {rightText==='Approve All' && 
            <FaIcons.FaCheck name="pencil" color={Colors.primary.white} size={12} style={{marginLeft: 8, marginBottom: 2}} />
            }
            {/* {rightText==='Approve All' && <>
            {menuOpen ?
            <FaIcons.FaChevronUp name="pencil" color={Colors.primary.white} size={12} style={{marginLeft: 4}} />
            :
            <FaIcons.FaChevronDown name="pencil" color={Colors.primary.white} size={12} style={{marginLeft: 4}} />
            }
            </>} */}
          </View>
        ) : (
          <View style={{backgroundColor: Colors.primary.red, borderRadius: 4, justifyContent:'center', alignItems:'center', height: 30, width: 30}}>
            <GoIcons.GoPencil name="pencil" color={Colors.primary.white} size={20} />
          </View>
        )}
      </TouchableOpacity>
      :
      <View style={{width: 40}} />
      }

      {exportData &&
        <TouchableOpacity 
        // disabled={rightDisabled}
        onPress={() => exportCSV()}
        style={styles.exportMenu}
        >
          <FaIcons.FaFileDownload color={Colors.primary.white} size={20} />
        </TouchableOpacity>
      }
      
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
      width: '100%',
      height: 36,
      flexDirection: "row",
      backgroundColor: Colors.primary.white,
      marginBottom: 12,
      alignItems:'center',
      justifyContent:'space-between'
  },
  exportMenu: {
    position: 'absolute',
    right: 12,
    backgroundColor: Colors.primary.blue, 
    borderRadius: 4, 
    justifyContent:'center', 
    alignItems:'center', 
    height: 36, width: 36,
  },

});