

export const Colors = {
    primary: {
        lightBlue: '#1DA1F2',
        white: '#FFF',
        black: '#000',
        error: '#EE1D52',
        red: '#FF4348',
        green: '#5cb85c',
        purple: '#7A67BB',
        gray: '#828282',
        darkGray: '#555555',
        lightGray: '#EDEDED',
        extremelyLightGray: '#fafafa',
        placeholder: '#B2BEB5',
        offWhite: '#F6F6F6',
        blue: 'rgba(92, 137, 211, 1)',
        teal: 'rgba(93,174,212,1)',
        test: '#777'
    },
    transaction: {
        red: 'red',
        green: 'green',
        purple: 'purple',
    }
}