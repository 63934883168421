import React, { useState, createContext, useEffect, useContext } from "react";
import { listenToParts, updatePart } from "services/parts-service";
import { getCompanyStagedItems } from "services/purchaseOrders-service";
import { firestore } from "utils/firebase";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";
import {
  getHistory,
  getLocationParts,
  getParts,
  getServiceItems,
  getSpecificStagedItems,
} from "~/services/parts-service";
import { useAuth } from "./AuthContext";
import { useCompany } from "./CompanyContext";
import { usePersistState } from "./usePersistState";

export const PartsContext = createContext();
let timer;
let alreadyLooping = false;
export const PartsProvider = ({ children }) => {
  const {
    company,
    setLoading,
    setCompany,
    setIsDTools,
    setUsers,
    setLocations,
    setSelectableLocations,
    stagingAreaSubLocations,
  } = useCompany();
  const { currentUser, currentAuth } = useAuth();

  const [parts, setParts, partsFetched] = usePersistState("PARTS_parts", null);
  const [startListenToParts, setStartListenToParts] = useState(false);
  const [locationParts, setLocationParts] = usePersistState(
    "PARTS_locationParts",
    null
  );
  const [minMaxParts, setMinMaxParts] = usePersistState(
    "PARTS_minMaxParts",
    null
  );

  const [serviceItems, setServiceItems] = usePersistState(
    "PARTS_serviceItems",
    null
  );
  const [manufacturers, setManufacturers] = usePersistState(
    "PARTS_manufacturers",
    null
  );
  const [getManufacturers, setGetManufacturers] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [models, setModels] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemHistory, setItemHistory] = useState(null);
  const [itemByLocation, setItemByLocation] = useState(null);

  const [stagedItems, setStagedItems] = useState(null);
  const [stagedParts, setStagedParts] = useState(null);
  const [getStagedItems, setGetStagedItems] = useState(false);

  const [
    initialSync,
    setInitialSync,
    initialSyncFetched,
    setInitialSyncFetched,
  ] = usePersistState("PARTS_initialSync", null);
  const [syncingInitialData, setSyncingInitialData] = useState(false);

  function clearState() {
    console.log("clearing state")
    setCompany(null);
    setIsDTools(false);
    setUsers(null);
    setLocations(null);
    setSelectableLocations(null);
    setInitialSync(null);
    setInitialSyncFetched(false);
    setSyncingInitialData(false);
    setParts(null);
    setMinMaxParts(null);
    setStartListenToParts(false);
    setLocationParts(null);
    setServiceItems(null);
    setManufacturers(null);
    setGetManufacturers(false);
    window.location.reload();
  }

  useEffect(() => {
    // console.log('here', currentAuth?.uid)
    if (initialSyncFetched && !initialSync) {
      //   console.log('should start?')
      timer = setTimeout(() => {
        // console.log('should set?')
        setSyncingInitialData(true);
      }, 500);
    } else if (initialSyncFetched && initialSync) {
      //   console.log('should set false?')
      setSyncingInitialData(false);
    }
    return () => {
      //   console.log('should clear?')
      timer && clearTimeout(timer);
    };
  }, [initialSync, initialSyncFetched]);

  useEffect(() => {
    if (company) {
      //    setStartGetParts(true)
      setStartListenToParts(true);
    }
  }, [company]);

  let partSub
  useEffect(() => {
    if (!startListenToParts || !partsFetched || !initialSyncFetched) return;
    // console.log('start listen to parts', !startListenToParts, !partsFetched, !initialSyncFetched)
    partSub = listenToParts(
      company,
      initialSync,
      setInitialSync,
      parts,
      gotParts,
      setGetManufacturers,
      setLoading
    );

    return () => partSub && partSub();
  }, [startListenToParts, partsFetched, initialSyncFetched]);

  function gotParts(parts) {
    setParts(parts);
    // console.log('setting parts', parts?.length)
  }

  /** LOCATION PARTS */
  useEffect(() => {
    

    let subscriber;
    if (startListenToParts && parts && company) {
      // console.log('parts changed', parts?.length)
      subscriber = getLocationParts(company).onSnapshot((documentSnapshot) => {
        // console.log('change in locationParts', documentSnapshot.docs.length)
        const temp = [];

        documentSnapshot &&
          documentSnapshot.forEach((doc) => {
            const data = doc.data();
            const realPart = parts.find((x) => x.id === data.partID);
            if (realPart) {
              let part = {
                id: doc.id,
                ...realPart,
              };
              part.locationQty = data.qty;
              part.minStockQty = data.minStockQty ? data.minStockQty : 0;
              part.maxStockQty = data.maxStockQty ? data.maxStockQty : 0;
              part.subLocation = data.subLocation
                ? data.subLocation
                : undefined;
              part.locationID = data.locationID;
              part.partID = data.partID;
              part.locationPartId = `${data.locationID}-${data.partID}`;

              temp.push(part);
            }
          });

        const sorted = temp.sort((a, b) =>
          a.lowercaseMfg > b.lowercaseMfg && a.lowercaseModel > b.lowercaseModel
            ? 1
            : -1
        );
        setLocationParts(sorted);
      });

      getServiceItems(company, setServiceItems);

      return () => subscriber && subscriber();
    } else {
      subscriber && subscriber();
    }
  }, [parts, startListenToParts]);

  

  useEffect(() => {
    if (locationParts && parts) {
      
      const min = [];
      const max = [];
      locationParts.forEach((part) => {
        //min max
        part.onOrderQty = 0;
        if (part.minStockQty && part.minStockQty >= part.locationQty)
          min.push(part);
        if (part.maxStockQty && part.maxStockQty <= part.locationQty)
          max.push(part);
        let tempMinMax = min.concat(max);
        tempMinMax = tempMinMax.filter(
          (item, pos) => tempMinMax.indexOf(item) === pos
        );
        setMinMaxParts(tempMinMax.filter((x) => x?.active && !x?.archived));
      });

      const filteredParts = parts
      // .filter(
      //   (x) => x.qty === undefined || isNaN(x?.qty)
      // );
      if (alreadyLooping) return;
      console.log('test')
      alreadyLooping = true;
      const batch = firestore.batch();
      filteredParts.forEach((part) => {
        // if (part.id !== "592694ea-8a2c-4f2b-bda4-587e06ec8a72") return
        // console.log('part', part)
        if (!part.qty) part.qty = 0;
        const items = locationParts.filter((x) => x.partID === part.id);
        const sum = items?.length
          ? items
              .map((x) => x.locationQty)
              .reduce((a, b) => {
                return a + b;
              })
          : 0;

          
        if (sum !== part.qty) {
          console.log('sum', sum, part.qty, part.id)
          const docRef = firestore
            .collection("companies")
            .doc(company.id)
            .collection("parts")
            .doc(part.id);
          batch.update(docRef, { qty: sum, lastModified: new Date() });
          console.log('updated qty from parts context', part.id, sum, part.qty)
        }
        
      });
      batch.commit();
    }
  }, [locationParts, parts]);

  /** MANUFACTURERS */
  useEffect(() => {
    if (!getManufacturers) return;

    const filter = parts
      .filter((x) => isNaN(x?.totalValue))
      .map((x) => {
        x.totalValue = 0;
        return x;
      });

    const mfg = parts.filter((x) => !x.parentRefID);
    const sorted = mfg.sort((a, b) =>
      a.model.toUpperCase() > b.model.toUpperCase() ? 1 : -1
    );

    setManufacturers(sorted);
  }, [getManufacturers, parts]);

  /** ON SELECT MANUFACTURER */
  useEffect(() => {
    if (selectedManufacturer) {
      const filtered = parts.filter(
        (x) => x.parentRefID === selectedManufacturer.id
      );
      setModels(filtered);
    }
  }, [selectedManufacturer, parts]);

  //specific item was selected so lets get history, part by location, and staged items
  useEffect(() => {
    if (selectedItem) {
      setItemHistory(null);
      setItemByLocation(null);

      if (selectedItem.parentRefID) {
        const parent = parts.find((x) => x.id === selectedItem.parentRefID);
        if (parent) {
          selectedItem.manufacturer = parent.model;
          selectedItem.lowercaseMfg = parent.model.toLowerCase();
        }
      }

      //get history
      getHistory(company, selectedItem).then((querySnapshot) => {
        // console.log("history", querySnapshot.docs.length, selectedItem.id)
        const temp = [];
        querySnapshot.forEach((doc) => {
          const history = {
            id: doc.id,
            ...doc.data(),
          };
          temp.push(history);
        });
        // const sorted = temp.sort((a, b) => a.date.toDate().getTime() < b.date.toDate().getTime())
        setItemHistory(temp);
      });

      //get qty by location
      const filtered = locationParts.filter(
        (x) => x.partID === selectedItem.id
      );
      getSpecificStagedItems(
        company,
        selectedItem,
        filtered,
        setItemByLocation
      );
    }
  }, [selectedItem, locationParts]);

  /** START STAGING */
  useEffect(() => {
    let subscriber;
    if (!!getStagedItems && currentUser) {
      subscriber = getCompanyStagedItems(company).onSnapshot(
        (querySnapshot) => {
          const temp = [];
          querySnapshot &&
            querySnapshot.forEach((doc) => {
              // console.log('staging item', doc.id)
              const data = doc.data();
              if (data.deleted) return;
              const item = {
                ...data,
                id: doc.id,
              };
              if (!item.customerId) return; //stock - probably will never trigger - being handled by cloud function

              const part = parts.find((x) => x.id === item.itemListId);
              if (part)
                item.itemFullName = part.manufacturer
                  ? getItemName(part)
                  : getItemNameFromManufacturer(part, manufacturers);
              else if (!item.itemFullName) item.itemFullName = "deleted item";

              //only for staging report, not total valuation check
              const customer =
                getStagedItems?.length &&
                getStagedItems.find((x) => x.id === item.customerId);
              item.customerFullName =
                customer && customer.fullName
                  ? customer.fullName
                  : `deleted customer ${item?.customerId}`;
              if (
                customer &&
                customer?.stagingArea &&
                stagingAreaSubLocations.find(
                  (x) => x.id === customer?.stagingArea
                )
              ) {
                item.stagingArea = customer?.stagingArea;
              } else {
                item.stagingArea = null;
              }

              temp.push(item);
            });
          const sorted = temp.sort((a, b) =>
            a.customerFullName.toLowerCase() > b.customerFullName.toLowerCase()
              ? 1
              : -1
          );

          setStagedItems(sorted);
        },
        (error) => {
          console.log("error getting staged items", error);
        }
      );
      return () => subscriber && subscriber();
    } else {
      setGetStagedItems(false);
      subscriber && subscriber();
    }
  }, [getStagedItems]);

  //get actual parts for po items
  useEffect(() => {
    if (stagedItems && parts) {
      const temp = [];
      stagedItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.itemListId);
        if (part) {
          item.itemFullName = part.manufacturer
            ? getItemName(part)
            : getItemNameFromManufacturer(part, manufacturers);
        }
        item.cost = part ? part.cost : 0;
        if (!item.rejected) {
          temp.push(item);
        }
      });
      setStagedParts(temp);
    }
  }, [stagedItems, parts]);
  /** END STAGING */

  return (
    <PartsContext.Provider
      value={{
        parts,
        setParts,
        locationParts,
        setLocationParts,
        minMaxParts,
        serviceItems,
        setServiceItems,
        manufacturers,
        setManufacturers,
        selectedManufacturer,
        setSelectedManufacturer,
        models,
        setModels,
        selectedItem,
        setSelectedItem,
        itemHistory,
        setItemHistory,
        itemByLocation,
        setItemByLocation,
        stagedItems,
        setStagedItems,
        stagedParts,
        setStagedParts,
        getStagedItems,
        setGetStagedItems,
        initialSync,
        setInitialSync,
        initialSyncFetched,
        setInitialSyncFetched,
        syncingInitialData,
        clearState,
      }}
    >
      {children}
    </PartsContext.Provider>
  );
};

export const useParts = () => useContext(PartsContext);
