import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator, FlatList } from "react-native";
import Select from 'react-select';
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useParts } from "context/PartsContext";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { StagedItemCard } from "components/Cards/StagedItemCard";
import * as FaIcons from "react-icons/fa";
import { TouchableOpacity } from "react-native-web";
import { Fonts } from "constants/Fonts";
import { cloneDeep } from "lodash";
import { useCustomers } from "context/CustomersContext";
import { ConfirmModal } from "components/Modals/Confirmation";
import { markItemDelivered } from "services/transactions-service";
import { FullModal } from "components/Modals/FullModal";
import * as AiIcons from 'react-icons/ai'
import { useAuth } from "context/AuthContext";
import { formatStagingAreaReport } from "utils/exports-helper";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";
import { ListContainer } from "components/ListContainer";
import { VariableSizeList } from "react-window";

const {width, height} = Dimensions.get('window');

const options = [
  { value: 'customer', label: 'Customer' },
  { value: 'item', label: 'Item' },
  { value: 'createdAt', label: 'Created At' },
  { value: 'lastModified', label: 'Last Modified' },
];

const allStaging = {value: null, label: 'All'}
const defaultStaging = {value: 'default', label: 'Default'}

export function StagingArea() {

  const {users, company, stagingAreaSubLocations} = useCompany()
  const {stagedParts, setGetStagedItems, manufacturers} = useParts()
  const {customers} = useCustomers()
  const {currentUser} = useAuth()

  const [search, setSearch] = useState(null)
  const [selectedSort, setSelectedSort] = useState(options[0])
  const [ascending, setAscending] = useState(true)
  const [filtered, setFiltered] = useState(null)
  const [sorted, setSorted] = useState(null)

  const [stagingAreaOptions, setStagingAreaOptions] = useState([])
  const [selectedStagingArea, setSelectedStagingArea] = useState(allStaging)

  const [selectedItem, setSelectedItem] = useState(null)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stagingAreaSubLocations) {
      let options = stagingAreaSubLocations.map((x) => {
        return { value: x.id, label: x.name }
      })
      options.unshift(defaultStaging)
      options.unshift(allStaging)
      setStagingAreaOptions(options)
    } else {
      setStagingAreaOptions([allStaging, defaultStaging])
    }
  }, [stagingAreaSubLocations])

  useEffect(() => {
    setGetStagedItems(customers)
  }, [])

  useEffect(() => {
    if (loading && stagedParts) {
      setFiltered(stagedParts)
      setLoading(false)

      if (!stagedParts || !stagedParts?.length) return

      //filter old stagedParts
      let temp = stagedParts.filter(x => x.timeCreated && (x.timeCreated.toDate().getTime() > new Date('2023-07-11').getTime()))
      
    }
  }, [stagedParts])

  //on search change
  useEffect(() => {
    if (!stagedParts || !stagedParts?.length) return
    if (search) {
      let temp = stagedParts
      .filter(x => 
        x.itemFullName.toLowerCase().includes(search.toLowerCase())
        || x.customerFullName.toLowerCase().includes(search.toLowerCase())
      )
      if (selectedStagingArea && selectedStagingArea.value) {
        if (selectedStagingArea.value==='default') {
          temp = temp.filter(x =>  !x.stagingArea)
        } else {
          temp = temp.filter(x =>  x.stagingArea===selectedStagingArea?.value)
        }
      }
      
      setFiltered(temp)
    } else {
      if (selectedStagingArea && selectedStagingArea.value) {
        let temp
        if (selectedStagingArea.value==='default') {
          temp = stagedParts.filter(x =>  !x.stagingArea)
        } else {
          temp = stagedParts.filter(x =>  x.stagingArea===selectedStagingArea?.value)
        }
        
        setFiltered(temp)
      } else {
        setFiltered(stagedParts)
      }
    }
  }, [search, stagedParts, selectedStagingArea])

  useEffect(() => {
    if (filtered) {

      let temp = cloneDeep(filtered)

      switch (selectedSort.value) {
        case 'customer':
          temp = temp.sort((a,b) => sortObject(a.customerFullName.toLowerCase(), b.customerFullName.toLowerCase()))
          break;
        case 'item':
          temp = temp.sort((a,b) => sortObject(a.itemFullName.toLowerCase(), b.itemFullName.toLowerCase()))
          break;
        case 'createdAt':
          temp = temp.sort((a,b) => sortObject(a.timeCreated, b.timeCreated))
          break;
        case 'lastModified':
          temp = temp.sort((a,b) => sortObject(a.timeModified, b.timeModified))
          break;
        default:
          temp = temp.sort((a,b) => sortObject(a.customerFullName.toLowerCase(), b.customerFullName.toLowerCase()))
          break;
      }

      setSorted(temp)
    }
  }, [selectedSort, ascending, filtered])


  function sortObject(a, b) {

    if (a === b) return 0

    if (!ascending) {
      return a < b ? 1 : -1
    } else {
      return a < b ? -1 : 1
    }
  }

  function deliverItem(item) {
    setLoading(true)
    markItemDelivered(company, item, setLoading)
    setShowConfirmModal(false)
  }

  const selectedItemUser = selectedItem && users.find(x => x.id===selectedItem?.deliveredBy)
  let selectedItemUserName = null
  selectedItemUserName = selectedItemUser && selectedItemUser?.firstName + ' ' + selectedItemUser?.lastName

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid lightgray',
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({ 
      ...styles, 
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
  }

  function RenderItem({index, style}) {
    const item = sorted[index]
    return (
      <View style={style}>
        <StagedItemCard
        item={item}
        onPress={() => {
          // console.log('item', item)
          setSelectedItem(item)
        }}
        onDeliver={() => setShowConfirmModal(item)}
        admin={currentUser.admin}
        name={item.manufacturer ? getItemName(item) : getItemNameFromManufacturer(item, manufacturers)}
        stagingArea={item?.stagingArea ? stagingAreaSubLocations.find(x => x.id===item?.stagingArea) : null}
        />
      </View>
    )
  }

  const getItemSize = index => {
    // const item = sorted[index]
    return 65
    // if (item?.stagingArea) {
    //   return 65
    // } else {
    //   return 65
    // }
  }

  // console.log('staged items', stagedParts?.length)

  return (
    <View style={styles.container}>
      <Header 
      title={'Staging Area'} 
      exportData={formatStagingAreaReport(sorted, users, stagingAreaSubLocations)}
      // onBack={() => navigation.goBack()}
      />
      <View style={{zIndex: 999, flexDirection: 'row', width: '100%', alignItems:'center'}}>
          <CustomInput 
          placeholder={'Filter By Customer or Item'}
          style={{flex: 1, marginHorizontal: 12, height: 40}}
          onChange={setSearch}
          value={search}
          />
          <View style={{flexDirection: 'row', paddingLeft: 40, alignItems:'center'}}>
          <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, marginHorizontal: 4}} >Sort By:</PrimaryText>
          <Select
          styles={customStyles}
          value={selectedSort}
          onChange={(value) => setSelectedSort(value)}
          options={options}
          name={'test'}
          />
          <TouchableOpacity onPress={() => setAscending(!ascending)} style={{marginLeft: 12}}>
            {ascending ?
            <FaIcons.FaChevronUp size={16} color={Colors.primary.darkGray}/>
            :
            <FaIcons.FaChevronDown size={16} color={Colors.primary.darkGray}/>
            }
          </TouchableOpacity>
          
          </View>
      </View>
      {stagingAreaSubLocations && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 4,
            justifyContent: "flex-end",
            zIndex: 999,
            marginRight: 28
          }}
        >
          <PrimaryText fontSize={14} fontWeight={400}>
            {"Filter By Staging Area Sub-location:   "}
          </PrimaryText>
          <Select
            styles={customStyles}
            value={selectedStagingArea}
            onChange={(value) => setSelectedStagingArea(value)}
            options={stagingAreaOptions}
          />
        </View>
      )}

        
      {stagedParts ?
      <View style={styles.body}>
        <View style={styles.detailLabelContainer}>
          {/* <PrimaryText fontSize={14} fontWeight={400} style={{width: 40}} ></PrimaryText> */}
          <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1, color: Colors.primary.white}} >Item/Customer</PrimaryText>
          <View style={{flexDirection:'row'}}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Qty</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Rec</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Del</PrimaryText>
              {currentUser.admin &&
                <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, {width: width*0.06}]} ></PrimaryText>
              }
          </View>
        </View>

        <View style={{ flex: 1, width: width - 300 }}>
        <VariableSizeList
          height={height}
          width={width - 300}
          itemSize={getItemSize}
          itemCount={sorted ? sorted.length : 0}
        >
          {RenderItem}
        </VariableSizeList>
        </View>

        {/* <ListContainer
          items={sorted}
          size={70}
          renderItem={RenderItem}
        /> */}

      </View>
      :
          <View style={styles.noCustomerContainer}>
              <PrimaryText fontSize={16} fontWeight={400} >
                  {`No items in staging area`}
              </PrimaryText>
          </View>
      }
         
      {/** DETAIL MODAL */}
      {!!selectedItem &&
      <FullModal>
        
        <View style={styles.modalContent}>

          <View style={styles.modalHeader}>
            <PrimaryText fontSize={20} fontWeight={400} style={{width: 40}}></PrimaryText>
            <PrimaryText fontSize={16} fontWeight={400} style={{textAlign: 'center'}}>{selectedItem?.itemFullName}</PrimaryText>
            <TouchableOpacity onPress={() => setSelectedItem(null)}>
                <AiIcons.AiOutlineClose color={Colors.primary.darkGray} size={24} style={{width: 40}} />
            </TouchableOpacity>
          </View>
          

          <View style={{marginVertical: 12}}>

            {!selectedItem.stock &&
            <>
              <PrimaryText 
              fontSize={14} 
              fontWeight={300} 
              style={[styles.description, {marginTop: 12}]}>
                {`Ordered On: ${selectedItem.timeCreated.toDate().toDateString()}`}
              </PrimaryText>
              <PrimaryText 
              fontSize={14} 
              fontWeight={300} 
              style={[styles.description]}>
                {`Last Modified On: ${selectedItem.timeModified.toDate().toDateString()}`}
              </PrimaryText>
            </>
            }

            <View style={styles.lineBreak}/>

            <PrimaryText 
            fontSize={14} 
            fontWeight={300} 
            style={styles.description}
            >
              {!selectedItem.deliveredQuantity 
              ? 'Item has not been delivered' 
              : `${selectedItem.return ? 'Returned On: ' : 'Last Delivered On:'} ${selectedItem.deliveredDate.toDate().toDateString()}`
              }
            </PrimaryText>

            {!!selectedItem.deliveredQuantity &&
              <PrimaryText 
              fontSize={14} 
              fontWeight={300} 
              style={styles.description}
              >
                By: {selectedItemUserName || ''}
              </PrimaryText>
            }


            
          </View>
        </View>
      </FullModal>
      }

      {!!showConfirmModal &&
        <ConfirmModal
        title={'Deliver Item'}
        message={'Are you sure?'}
        onConfirm={() => deliverItem(showConfirmModal)}
        onCancel={() => setShowConfirmModal(false)}
        />
      }

      {loading &&
            <ActivityIndicator 
            animating={loading}
            size={'large'} 
            style={StyleSheet.absoluteFill} color={Colors.primary.blue} 
            />
        }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      width: '100%',
      flexDirection: "column",
      backgroundColor: Colors.primary.white,
  },
  noCustomerContainer:{
      flex: 1,
      justifyContent:'center',
      alignItems:'center'
  },
  body: {
      flex: 1,
      width: '100%',
      backgroundColor: Colors.primary.white,
      marginTop: 12,
      alignItems:'center'
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection:'row',
    justifyContent:'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
      // marginHorizontal: 6,
      width: 60,
      textAlign:'right',
      color: Colors.primary.white
  },


  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12
  },
  modalContent: {
    // height: height*0.6,
    width: 400,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  }


});
