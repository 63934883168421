import React, { forwardRef } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
  Text,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import { FlatList } from "react-native-web";

const { height, width } = Dimensions.get("window");

export const PurchaseOrderItemCard = ({
  item,
  onPress,
  admin,
  onDelete,
  open = false,
  onPrint = false,
  selectedCustomer,
  onEdit,
}) => {
  let style = styles(false);

  const printRef = React.useRef();

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const ComponentToPrint = React.forwardRef((props, ref) => {
    // console.log("item", item)
    let printingPages = [];
    let qty = item?.bulk ? 1 : item.quantity > 100 ? 100 : item.quantity;
    // const {item, selectedCustomer} = props;s
    for (let i = 0; i < qty; i++) {
      printingPages.push(
        <View style={{ display: "flex" }}>
          <View style={{ flex: 1 }}>
            <style type="text/css" media="print">
              {`@page { size: landscape; margin: 20px!important; }`}
            </style>
            <View
              style={{
                width: 360,
                aspectRatio: 360 / 148,
                justifyContent: "center",
                // backgroundColor: "red",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  fontSize={14}
                  fontWeight={600}
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    marginBottom: 12,
                  }}
                >
                  {selectedCustomer?.fullName || selectedCustomer?.name}
                </Text>
                <View
                  style={{
                    flex: 1,
                    // height: 100,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // backgroundColor: "blue",
                  }}
                >
                  {item.id && <QRCode value={item.id} size={100} />}
                  <View
                    style={{
                      flex: 1,
                      marginLeft: 12,
                      // backgroundColor: 'red',
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {/* <Text
                  fontSize={16}
                  fontWeight={500}
                  // style={{ textAlign: "center" }}
                  style={{ fontSize: 12, fontWeight: 500, marginBottom: 12 }}
                >
                  {"QTY: 1 of " +item.quantity}
                </Text> */}

                    <View style={{}}>
                      <Text
                        fontSize={16}
                        fontWeight={500}
                        // style={{ textAlign: "center" }}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {item?.itemFullName}
                      </Text>

                      <Text
                        fontSize={16}
                        fontWeight={500}
                        // style={{ textAlign: "center" }}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {`Location: ${item?.projectItems?.length && item?.projectItems[i] && item?.projectItems[i]?.location?.Name ? item?.projectItems[i]?.location?.Name : "________________"}`}
                      </Text>

                      {item?.poNumber && (
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          // style={{ textAlign: "center" }}
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            marginTop: 2,
                          }}
                        >
                          PO: {item?.poNumber}
                        </Text>
                      )}
                      {/* <Text
                    fontSize={14}
                    fontWeight={400}
                    // style={{ textAlign: "center" }}
                    style={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {new Date().toDateString()}
                  </Text> */}
                    </View>
                    {item?.timeModified && (
                      <View style={{}}>
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          // style={{ textAlign: "center" }}
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 2,
                          }}
                        >
                          {item?.receivedQuantity > 0
                            ? "Received: "
                            : "Ordered: "}
                          {item?.timeModified.toDate().toDateString()}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </View>

            <div style={{ pageBreakAfter: "always" }} />
          </View>
        </View>
      );
    }
    return (
      <div style={{ display: "none" }}>
        <View ref={ref} style={{ display: "flex" }}>
          {printingPages}
        </View>
      </div>
    );
  });

  // console.log(item?.ProjectSIItemIds)

  let {
    returnedQuantity = 0,
    quantity = 0,
    receivedQuantity = 0,
    deliveredQuantity = 0,
    itemFullName,
    customerId = "test",
    stock = false,
  } = item

  const strikeThrough = quantity-returnedQuantity <= 0;

  if (strikeThrough) {
    returnedQuantity = 0;
  }

  return (
    <>
      <ComponentToPrint
        ref={printRef}
        selectedCustomer={selectedCustomer}
        item={item}
      />
      <TouchableOpacity
        onPress={onPress}
        style={[
          style.card,
          quantity !== deliveredQuantity && {
            backgroundColor: Colors.primary.lightGray,
            borderColor: Colors.primary.white,
          },
        ]}
      >
        <PrimaryText
          fontSize={13}
          fontWeight={400}
          numberOfLines={1}
          style={[{flex: 1}, strikeThrough && {textDecorationLine: 'line-through'}]}
        >
          {itemFullName}
        </PrimaryText>
        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <View style={style.text}>
            <PrimaryText
              fontSize={13}
              fontWeight={400}
              style={[strikeThrough && {textDecorationLine: 'line-through'}]}
            // style={style.text}
            >
              {quantity-returnedQuantity}
            </PrimaryText>
          </View>
          <View style={style.text}>
            <PrimaryText
              fontSize={13}
              fontWeight={400}
              style={[strikeThrough && {textDecorationLine: 'line-through'}]}
            // style={style.text}
            >
              {receivedQuantity-returnedQuantity}
            </PrimaryText>
          </View>
          <View style={style.text}>
            <PrimaryText
              fontSize={13}
              fontWeight={400}
              style={[strikeThrough && {textDecorationLine: 'line-through'}]}
            // style={style.text}
            >
              {deliveredQuantity-returnedQuantity}
            </PrimaryText>
          </View>

          {open && (
            <View style={style.text}>
              <PrimaryText
                fontSize={13}
                fontWeight={400}
              // style={style.text}
              >
                {item?.poNumber}
              </PrimaryText>
            </View>
          )}
          {onPrint && (
            <View style={style.printButtonContainer}>
              <ReactToPrint
                trigger={() => (
                  <TouchableOpacity
                    onPress={() => {
                      print();
                    }}
                    style={style.printButton}
                  >
                    <FaIcons.FaPrint size={14} color={Colors.primary.white} />
                  </TouchableOpacity>
                )}
                content={() => printRef.current}
              />
            </View>
          )}
          {admin && !open && (
            <View style={style.deleteButtonContainer}>
              <TouchableOpacity onPress={onDelete} style={style.deleteButton}>
                <FaIcons.FaTrashAlt size={14} color={Colors.primary.white} />
              </TouchableOpacity>
            </View>
          )}
          {admin &&
            !open &&
            (!deliveredQuantity ||
              (deliveredQuantity &&
                quantity !== deliveredQuantity)) ? (
            <View style={style.deleteButtonContainer}>
              <TouchableOpacity onPress={onEdit} style={style.editButton}>
                <FaIcons.FaExchangeAlt size={14} color={Colors.primary.white} />
              </TouchableOpacity>
            </View>
          ) : !open ? (
            <View style={style.deleteButtonContainer}></View>
          ) : null}
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      // width: "100%",
      paddingHorizontal: 12,
      paddingVertical: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
      // backgroundColor: Colors.primary.red
    },
    text: {
      // marginHorizontal: 6,
      width: width * 0.04,
      // textAlign: "right",
      alignItems: "center",
    },
    deleteButtonContainer: {
      width: width * 0.03,
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: Colors.primary.red,
      padding: 8,
      borderRadius: 4,
    },
    editButton: {
      backgroundColor: Colors.primary.gray,
      padding: 8,
      borderRadius: 4,
    },
    printButtonContainer: {
      width: width * 0.03,
      alignItems: "center",
    },
    printButton: {
      backgroundColor: Colors.primary.green,
      padding: 8,
      borderRadius: 4,
    },
  });
