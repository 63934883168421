import { firestore } from "utils/firebase";
import { Alert } from 'react-native';

export const getCompany = (auth) => {
  // console.log(auth)
  const email = auth.email
  return firestore.collection('companies').where('userEmails', 'array-contains', email)
}

export const updateCompany = (company, data, onSuccess) => {
  firestore
    .collection('companies')
    .doc(company.id)
    .update(data)
    .then(() => {
      onSuccess && onSuccess()
    })
}

export const getUsers = (companyId, setUsers, setActiveUsers) => {
  // console.log(companyId, userId)
  return firestore
  .collection('companies')
  .doc(companyId)
  .collection('users')
  .onSnapshot(querySnapshot => {
    const temp = []
    // const tempActive = []
    querySnapshot.forEach(doc => {
        const user = {
            id: doc.id,
            ...doc.data()
        }
        temp.push(user)
    })

    const sorted = temp.sort((a,b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1)
    setUsers(sorted)

    const tempActive = sorted.filter(x => x?.active)
    // tempActive.unshift({firstName: 'All', id: 'all'})
    setActiveUsers(tempActive)

  })
}

export const getCompanyHistoryByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .where("date", ">=", startDate)
    .where("date", "<=", endDate)
    .orderBy("date", "desc")
}

export const getDeliveredItemsByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('purchaseOrders')
    .where("deliveredDate", ">=", startDate)
    .where("deliveredDate", "<=", endDate)
    .orderBy("deliveredDate", "desc")
}

export const getDeliveredStockItemsByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .where("date", ">=", startDate)
    .where("date", "<=", endDate)
    .orderBy("date", "desc")
}

export const updateHistoryItem = (company, item, data) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .doc(item.id)
    .update(data)
}

export const getSerialNumbers = (company) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('serialNumbers')
}