import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import Select from "react-select";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { Fonts } from "constants/Fonts";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { EditInventoryLocationCard } from "components/Cards/EditInventoryLocationCard";
import { FullModal } from "components/Modals/FullModal";
import {
  addNewLocation,
  updateDefaultLocation,
  updateLocation,
} from "services/locations-service";
import { ModalHeader } from "components/Modals/ModalHeader";
import { SelectItems } from "components/Items/SelectItems";
import { isParentItem } from "utils/helpers";
import { useParts } from "context/PartsContext";
import { firestore } from "utils/firebase";
import { ScrollView } from "react-native-web";
import { updateCompany } from "services/company-service";

const options = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

const { height } = Dimensions.get("window");

export function InventoryLocations() {
  const {
    company,
    locations,
    users,
    stagingAreaSubLocations,
    setStagingAreaSubLocations,
  } = useCompany();
  const { parts, locationParts } = useParts();

  const [search, setSearch] = useState(null);
  const [selectedView, setSelectedView] = useState(options[0]);
  const [ascending, setAscending] = useState(true);
  const [filtered, setFiltered] = useState(locations);
  const [sorted, setSorted] = useState(null);

  const [newLocationName, setNewLocationName] = useState(null);
  const [showAddNewLocation, setShowAddNewLocation] = useState(false);
  const [showEditLocation, setShowEditLocation] = useState(false);
  const [showEditStagingArea, setShowEditStagingArea] = useState(false);

  /** SUB-LOCATIONS */
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [showAssignLocationModal, setShowAssignLocationModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentSubLocationItems, setCurrentSubLocationItems] = useState([]); //used to compare against selcted items
  const [showSaveSubLocation, setShowSaveSubLocation] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showStagingConfirmModal, setShowStagingConfirmModal] = useState(false);
  const [showDefaultConfirm, setShowDefaultConfirm] = useState(false);

  const [selectedStagingSubLocation, setSelectedStagingSubLocation] =
    useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  //on search change
  useEffect(() => {
    if (search) {
      const temp = locations.filter((x) =>
        x.lowercaseName.includes(search.toLowerCase())
      );

      setFiltered(temp);
    } else {
      setFiltered(locations);
    }
  }, [search, locations]);

  useEffect(() => {
    if (filtered) {
      let temp = cloneDeep(filtered);

      switch (selectedView.value) {
        case "active":
          temp = temp.filter((x) => x.active);
          break;
        case "archived":
          temp = temp.filter((x) => !x.active);
          break;
        default:
          break;
      }

      temp.sort((a, b) => sortObject(a.lowercaseName, b.lowercaseName));

      setSorted(temp);
    }
  }, [selectedView, ascending, filtered]);

  useEffect(() => {
    !showEditLocation && !showConfirmModal && setSelectedLocation(null);
  }, [showEditLocation, showConfirmModal]);

  function sortObject(a, b) {
    if (a === b) return 0;

    if (!ascending) {
      return a < b ? 1 : -1;
    } else {
      return a < b ? -1 : 1;
    }
  }

  function onArchiveLocation(location) {
    setSelectedLocation(location);
    setShowConfirmModal(true);
  }

  function archive() {
    console.log("archive location", selectedLocation);
    setLoading(true);
    const data = {
      active: !selectedLocation.active,
    };
    updateLocation(company, selectedLocation, data, onSuccessLocationSave);
  }

  function addLocation() {
    setLoading(true);
    addNewLocation(company, newLocationName, onSuccessLocationSave);
  }

  function editLocation(location) {
    setSelectedLocation(location);
    setNewLocationName(location?.name);
    setShowEditLocation(true);
  }

  function onMakeDefault(location) {
    setSelectedLocation(location);
    setShowDefaultConfirm(true);
  }

  function makeDefaultConfirmed() {
    updateDefaultLocation(company, selectedLocation, onSuccessLocationSave);
  }

  function saveLocation() {
    setLoading(true);
    const data = {
      name: newLocationName,
      lowercaseName: newLocationName.toLowerCase(),
      subLocations: selectedLocation.subLocations || null,
    };
    if (selectedLocation.id === company.defaultLocationId) {
      const defaultData = {
        id: selectedLocation.id,
        name: newLocationName,
      }
      updateDefaultLocation(company, defaultData, () => {});
    }
    updateLocation(company, selectedLocation, data, onSuccessLocationSave);
    
  }

  function onSuccessLocationSave() {
    setShowAddNewLocation(false);
    setShowEditLocation(false);
    setShowDefaultConfirm(false);
    setShowConfirmModal(false);
    setLoading(false);
    setNewLocationName(null);
    setSelectedLocation(null);
    setSelectedSubLocation(null);
  }

  /** SUB LOCATIONS */
  function addSubLocation() {
    let name = window.prompt("New sub-location", "");
    if (name) {
      const subLocation = {
        id: Math.floor(Math.random() * Date.now()),
        name,
        active: true,
      };
      if (selectedLocation.subLocations) {
        selectedLocation.subLocations.push(subLocation);
      } else {
        selectedLocation.subLocations = [subLocation];
      }
      saveSubLocation();
    }
  }

  function editSubLocation(subLocation) {
    setSelectedSubLocation(subLocation);
    let name = window.prompt("Edit sub-location name", subLocation.name);
    if (name) {
      const findSublocation = selectedLocation.subLocations.find(
        (x) => x.id === subLocation.id
      );
      findSublocation.name = name;
      saveSubLocation();
    }
  }

  function onArchiveSubLocation(subLocation) {
    setSelectedSubLocation(subLocation);
    setShowConfirmModal(true);
  }

  function archiveSubLocation() {
    setLoading(true);
    const items = locationParts.filter((x) => x.subLocation === selectedSubLocation.id);
    removeAssignedItems(items);
    const findSublocation = selectedLocation.subLocations.find(
      (x) => x.id === selectedSubLocation.id
    );
    findSublocation.active = !findSublocation.active;
    saveSubLocation();
  }

  function saveSubLocation() {
    setLoading(true);
    const data = {
      subLocations: selectedLocation.subLocations || null,
    };
    updateLocation(company, selectedLocation, data, onSuccessSubLocationSave);
  }

  function onSuccessSubLocationSave() {
    setShowAddNewLocation(false);
    setShowEditLocation(false);
    setShowDefaultConfirm(false);
    setShowConfirmModal(false);
    setLoading(false);
    setSelectedSubLocation(null);
  }

  function onAssignItems(subLocation) {
    setSelectedItems([]);
    const items = locationParts.filter((x) => x.subLocation === subLocation.id);
    setSelectedItems(items ? items : []);
    setCurrentSubLocationItems(items ? items : []);
    setSelectedSubLocation(subLocation);
    setShowAssignLocationModal(true);
  }

  function onSelectPressed(item) {
    let clone = cloneDeep(selectedItems);
    if (isParentItem(parts, item)) {
      //parent so loop and grab all children
      const children = parts.filter((x) => x.parentRefID === item.id);

      //check if removing or adding parent
      if (selectedItems.find((x) => x.id === item.id)) {
        clone = clone.filter((x) => x.id !== item.id);
        children &&
          children.forEach((part) => {
            if (selectedItems.find((x) => x.id === part.id)) {
              clone = clone.filter((x) => x.id !== part.id);
            }
          });
      } else {
        clone.push(item);
        children &&
          children.forEach((part) => {
            if (!selectedItems.find((x) => x.id === part.id)) {
              clone.push(part);
            }
          });
      }
    } else {
      //child so just add/remove
      if (selectedItems.find((x) => x.id === item.id)) {
        clone = clone.filter(
          (x) => x.id !== item.id && x.id !== item.parentRefID
        );
      } else {
        clone.push(item);
      }
    }
    setSelectedItems(clone);
  }

  function saveAssignedItemsConfirmed() {
    setShowSaveSubLocation(false);
    setLoading(true);
    const batch = firestore.batch();
    selectedItems.forEach((item) => {
      if (isParentItem(parts, item)) return;
      const location = selectedLocation;
      const locationPartId = `${location.id}-${item.id}`;
      const exists = locationParts.find(
        (x) => x.locationPartId === locationPartId
      );
      const locationPartRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("locationParts")
        .doc(locationPartId);

      if (exists) {
        const data = {
          subLocation: selectedSubLocation.id,
        };
        batch.update(locationPartRef, data);
      } else {
        const locationPart = {
          locationID: location.id,
          locationName: location.name,
          lowercaseMfg: item.lowercaseMfg,
          lowercaseModel: item.lowercaseModel,
          partID: item.id,
          qty: 0,
          subLocation: selectedSubLocation.id,
        };
        batch.set(locationPartRef, locationPart);
      }
    });
    currentSubLocationItems.forEach((item) => {
      const stillAssigned = selectedItems.find((x) => x.id === item.id);
      if (!stillAssigned) {
        if (isParentItem(parts, item)) return;
        const location = selectedLocation;
        const locationPartId = `${location.id}-${item.id}`;
        const exists = locationParts.find(
          (x) => x.locationPartId === locationPartId
        );
        const locationPartRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("locationParts")
          .doc(locationPartId);

        if (exists) {
          const data = {
            subLocation: null,
          };
          batch.update(locationPartRef, data);
        } else {
          const locationPart = {
            locationID: location.id,
            locationName: location.name,
            lowercaseMfg: item.lowercaseMfg,
            lowercaseModel: item.lowercaseModel,
            partID: item.id,
            qty: 0,
            subLocation: null,
          };
          batch.set(locationPartRef, locationPart);
        }
      }
    });
    batch
      .commit()
      .then(() => {
        setSelectedItems([]);
        setCurrentSubLocationItems([]);
        setShowAssignLocationModal(false);
        setShowEditLocation(false);
        setSelectedLocation(null);
        setSelectedSubLocation(null);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error saving edited item", e);
        setLoading(false);
        window.alert(
          "Error updating items. Check internet connection and try again"
        );
      });
  }

  function removeAssignedItems(selectedItems) {
    setLoading(true);
    const batch = firestore.batch();
    selectedItems.forEach((item) => {
      if (isParentItem(parts, item)) return;

      const locationPartRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("locationParts")
        .doc(item.locationPartId);

      const data = {
        subLocation: null,
      };
      batch.update(locationPartRef, data);
      console.log(item, data);
    });
    batch
      .commit()
      .then(() => {
        setSelectedItems([]);
        setCurrentSubLocationItems([]);
        setShowAssignLocationModal(false);
        setShowEditLocation(false);
        setSelectedLocation(null);
        setSelectedSubLocation(null);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error saving edited item", e);
        setLoading(false);
        window.alert(
          "Error updating items. Check internet connection and try again"
        );
      });
  }

  /** STAGING */
  function addStagingSubLocation() {
    let name = window.prompt("New sub-location", "");
    if (name) {
      const subLocation = {
        id: Math.floor(Math.random() * Date.now()),
        name,
        active: true,
      };
      // if (stagingAreaSubLocations) {
      stagingAreaSubLocations.push(subLocation);
      // } else {
      //   setStagingAreaSubLocations([subLocation])
      //   // stagingAreaSubLocations = [subLocation]
      // }
      saveStagingSubLocation();
    }
  }

  function editStagingSubLocation(subLocation) {
    setSelectedStagingSubLocation(subLocation);
    let name = window.prompt("Edit sub-location name", subLocation.name);
    if (name) {
      const findSublocation = stagingAreaSubLocations.find(
        (x) => x.id === subLocation.id
      );
      findSublocation.name = name;
      saveStagingSubLocation();
    }
  }

  function onArchiveStagingSubLocation(subLocation) {
    setSelectedStagingSubLocation(subLocation);
    setShowStagingConfirmModal(true);
  }

  function archiveStagingSubLocation() {
    setLoading(true);
    const findSublocation = stagingAreaSubLocations.find(
      (x) => x.id === selectedStagingSubLocation.id
    );
    findSublocation.active = !findSublocation.active;
    saveStagingSubLocation();
  }

  function saveStagingSubLocation() {
    setLoading(true);
    const data = {
      stagingAreaSubLocations: stagingAreaSubLocations || null,
    };
    updateCompany(company, data, onSuccessStagingSubLocationSave);
  }

  function onSuccessStagingSubLocationSave() {
    setShowStagingConfirmModal(false);
    setLoading(false);
    setSelectedStagingSubLocation(null);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  };

  return (
    <View style={styles.container}>
      <Header
        title={"Inventory Locations"}
        // onBack={() => navigation.goBack()}
      />
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={styles.button}
          onPress={() => setShowAddNewLocation(true)}
        >
          <PrimaryText
            fontSize={14}
            fontWeight={300}
            style={{ color: Colors.primary.white, marginRight: 12 }}
          >
            Add Location
          </PrimaryText>
          <FaIcons.FaPlus size={16} color={Colors.primary.white} />
        </TouchableOpacity>
        <CustomInput
          placeholder={"Filter By Location Name"}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />
        <View
          style={{
            flexDirection: "row",
            paddingLeft: 40,
            alignItems: "center",
          }}
        >
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
          >
            View:
          </PrimaryText>
          <Select
            styles={customStyles}
            value={selectedView}
            onChange={(value) => setSelectedView(value)}
            options={options}
            name={"test"}
          />
          <TouchableOpacity
            onPress={() => setAscending(!ascending)}
            style={{ marginLeft: 12 }}
          >
            {ascending ? (
              <FaIcons.FaChevronUp size={16} color={Colors.primary.darkGray} />
            ) : (
              <FaIcons.FaChevronDown
                size={16}
                color={Colors.primary.darkGray}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>

      <TouchableOpacity
        style={[
          styles.button2,
          {
            width: 260,
            alignSelf: "center",
            backgroundColor: Colors.primary.green,
            margin: 0,
            marginTop: 12,
          },
        ]}
        onPress={() => setShowEditStagingArea(true)}
      >
        <PrimaryText
          fontSize={14}
          fontWeight={300}
          style={{ color: Colors.primary.white, marginRight: 12 }}
        >
          Edit Staging Area Sub-locations
        </PrimaryText>
      </TouchableOpacity>

      <View style={styles.body}>
        <View style={styles.detailLabelContainer}>
          {/* <PrimaryText fontSize={14} fontWeight={400} style={{width: 40}} ></PrimaryText> */}
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ flex: 1, color: Colors.primary.white, marginRight: 12 }}
          >
            Name
          </PrimaryText>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ flex: 1, color: Colors.primary.white }}
          >
            Assigned User
          </PrimaryText>
          <View style={{ flexDirection: "row" }}>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={styles.detailLabel}
            >
              Edit
            </PrimaryText>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={styles.detailLabel}
            >
              {selectedView.value === "active" ? "Archive" : "Unarchive"}
            </PrimaryText>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={styles.detailLabel}
            >
              Default
            </PrimaryText>
          </View>
        </View>

        <FlatList
          style={{ flex: 1, width: "100%" }}
          // contentContainerStyle={{width: '100%'}}
          data={sorted}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => {
            return (
              <EditInventoryLocationCard
                item={item}
                onArchive={() => onArchiveLocation(item)}
                onEdit={() => editLocation(item)}
                user={users.find((x) => x.vehicle === item.id)}
                isDefault={item.id === company.defaultLocationId}
                onMakeDefault={() => onMakeDefault(item)}
              />
            );
          }}
        />
      </View>

      {/** NEW LOCATION */}
      {showAddNewLocation && (
        <FullModal>
          <ModalHeader
            title={"New Location"}
            onClose={() => setShowAddNewLocation(false)}
          />
          <View style={styles.newLocationModal}>
            <CustomInput
              value={newLocationName}
              onChange={(text) => setNewLocationName(text)}
              style={{ width: 400 }}
            />
            {newLocationName && (
              <TouchableOpacity
                style={styles.button2}
                onPress={() => addLocation()}
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={{ color: Colors.primary.white }}
                >
                  Add
                </PrimaryText>
              </TouchableOpacity>
            )}
          </View>
        </FullModal>
      )}

      {/** EDIT LOCATION */}
      {showEditLocation && (
        <FullModal>
          <ScrollView style={{ maxHeight: height * 0.8 }}>
            <ModalHeader
              title={"Edit Location"}
              onClose={() => setShowEditLocation(false)}
            />
            {/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
            <View style={styles.newLocationModal}>
              <CustomInput
                value={newLocationName}
                onChange={(text) => setNewLocationName(text)}
                style={{ width: 400 }}
              />
              {newLocationName && (
                <TouchableOpacity
                  style={styles.button2}
                  onPress={() => saveLocation()}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={300}
                    style={{ color: Colors.primary.white }}
                  >
                    Save
                  </PrimaryText>
                </TouchableOpacity>
              )}
            </View>
            <PrimaryText
              fontSize={16}
              fontWeight={500}
              style={{
                textAlign: "center",
                marginTop: 24,
                textDecoration: "underline",
              }}
            >
              Sub-locations
            </PrimaryText>
            <TouchableOpacity
              style={styles.button}
              onPress={() => addSubLocation()}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={300}
                style={{ color: Colors.primary.white, marginRight: 12 }}
              >
                Add Sub-location
              </PrimaryText>
              <FaIcons.FaPlus size={16} color={Colors.primary.white} />
            </TouchableOpacity>
            {selectedLocation?.subLocations &&
            selectedLocation?.subLocations?.length > 0 ? (
              <FlatList
                style={{ flex: 1, width: "100%" }}
                // contentContainerStyle={{width: '100%'}}
                data={selectedLocation?.subLocations
                  .filter((x) => x.active)
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  // if (!item.active) return
                  return (
                    <EditInventoryLocationCard
                      item={item}
                      onArchive={() => onArchiveSubLocation(item)}
                      onEdit={() => editSubLocation(item)}
                      user={users.find((x) => x.vehicle === item.id)}
                      subLocation={true}
                      onAssignItems={() => onAssignItems(item)}
                    />
                  );
                }}
              />
            ) : (
              <View>
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={{ textAlign: "center", marginTop: 24 }}
                >
                  No Sub-locations
                </PrimaryText>
              </View>
            )}
          </ScrollView>
        </FullModal>
      )}

      {/** BULK ASSIGN SUB-LOCATION */}
      {showAssignLocationModal && (
        <FullModal>
          <View style={{ minWidth: 500, height: height * 0.8 }}>
            <ModalHeader
              title={`Assign Items To ${selectedLocation?.name} ${selectedSubLocation?.name}`}
              onClose={() => setShowAssignLocationModal(false)}
            />
            <SelectItems
              onSelectPressed={onSelectPressed}
              selectedItems={selectedItems}
              onRight={() => setShowSaveSubLocation(true)}
              rightText={"Assign"}
              rightDisabled={selectedItems.length === 0}
            />
          </View>
        </FullModal>
      )}

      {/** ARCHIVE LOCATION */}
      {showConfirmModal && (
        <ConfirmModal
          title={
            selectedLocation?.active
              ? `${
                  selectedSubLocation
                    ? "Delete Sub-location"
                    : "Archive Location"
                }`
              : `Unarchive Location`
          }
          message={`Are you sure?${!selectedSubLocation ? "\nThis will adjust all location quantities and values to zero." : ""}`}
          onConfirm={() =>
            selectedSubLocation ? archiveSubLocation() : archive()
          }
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {/** ARCHIVE LOCATION */}
      {showDefaultConfirm && (
        <ConfirmModal
          title={"Change Default Location"}
          message={"Are you sure?"}
          onConfirm={() => makeDefaultConfirmed()}
          onCancel={() => setShowDefaultConfirm(false)}
        />
      )}

      {/** SAVE SUB-LOCATION ASSIGNMENTS */}
      {showSaveSubLocation && (
        <ConfirmModal
          title={"Save sub-location assignments"}
          message={"Are you sure?"}
          onConfirm={() => saveAssignedItemsConfirmed()}
          onCancel={() => setShowSaveSubLocation(false)}
        />
      )}

      {/** EDIT STAGING AREA SUB-LOCATION */}
      {showEditStagingArea && (
        <FullModal>
          <ScrollView style={{ maxHeight: height * 0.8, width: 500 }}>
            <ModalHeader
              title={"Edit Staging Area Sub-locations"}
              onClose={() => setShowEditStagingArea(false)}
            />
            <PrimaryText fontSize={12} fontWeight={400}>
              *This feature allows you to assign specific staging areas to
              projects and run more detailed reports.
            </PrimaryText>
            <PrimaryText
              fontSize={16}
              fontWeight={500}
              style={{
                textAlign: "center",
                marginTop: 24,
                textDecoration: "underline",
              }}
            >
              Sub-locations
            </PrimaryText>
            <TouchableOpacity
              style={[styles.button2, { width: undefined }]}
              onPress={() => addStagingSubLocation()}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={300}
                style={{ color: Colors.primary.white, marginRight: 12 }}
              >
                Add Sub-location
              </PrimaryText>
              <FaIcons.FaPlus size={16} color={Colors.primary.white} />
            </TouchableOpacity>
            {stagingAreaSubLocations && stagingAreaSubLocations?.length > 0 ? (
              <FlatList
                style={{ flex: 1, width: "100%" }}
                // contentContainerStyle={{width: '100%'}}
                data={stagingAreaSubLocations}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  if (!item.active) return;
                  //   return null
                  return (
                    <EditInventoryLocationCard
                      item={item}
                      onArchive={() => onArchiveStagingSubLocation(item)}
                      onEdit={() => editStagingSubLocation(item)}
                      subLocation={true}
                    />
                  );
                }}
              />
            ) : (
              <View>
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={{ textAlign: "center", marginTop: 24 }}
                >
                  No Sub-locations
                </PrimaryText>
              </View>
            )}
          </ScrollView>
        </FullModal>
      )}

      {/** ARCHIVE LOCATION */}
      {showStagingConfirmModal && (
        <ConfirmModal
          title={
            selectedStagingSubLocation?.active
              ? `${
                  selectedStagingSubLocation
                    ? "Delete Sub-location"
                    : "Archive Location"
                }`
              : `Unarchive Location`
          }
          message={"Are you sure?"}
          onConfirm={() => archiveStagingSubLocation()}
          onCancel={() => setShowStagingConfirmModal(false)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  button: {
    flexDirection: "row",
    backgroundColor: Colors.primary.blue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  button2: {
    flexDirection: "row",
    backgroundColor: Colors.primary.blue,
    width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    // marginHorizontal: 6,
    width: 100,
    textAlign: "center",
    color: Colors.primary.white,
  },

  newLocationModal: {
    flexDirection: "row",
    alignItems: "center",
  },
});
