export const Links = {
    Landing: '/',

    Dashboard: '/dashboard',

    Inventory: '/inventory',

    Transactions: '/transactions',
    StagingAreaTransaction: '/stagingAreaTransaction',
    Receive: '/receive',

    Approvals: '/approvals',

    Serial: '/serial',

    Projects: '/projects',
    InventoryByLocation: '/inventoryByLocation',
    StagingArea: '/stagingArea',
    History: '/history',
    Audits: '/audits',
    AuditReport: '/auditReport',
    Quick: '/quick',
    MinMax: '/minMax',
    Delivered: '/delivered',
    Returned: '/returned',
    AgingItems: '/agingItems',
    MostValuableItems: '/mostValuableItems',
    ValueByLocation: '/valueByLocation',
    BStock: '/bstock',
    OpenPurchaseOrders: '/openPurchaseOrders',

    Settings: '/settings',
    InventoryLocations: '/inventoryLocations',
    Users: '/users',
    TotalValuation: '/valuation',
    QuickBooks: '/quickbooks',
    DTools: '/dTools',


    MyRequests: 'MyRequests',
    SubmitRequest: 'SubmitRequest',
    RequestDetail: 'RequestDetail',

    Expired: '/expired',
    Onboarding: '/onboarding',

    Support: '/support',
    DToolsOverview: '/dToolsOverview',
    DToolsInventoryLocations: '/dToolsInventoryLocations',
    

}