import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  Dimensions,
  ActivityIndicator,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { login } from "~/services/auth-service";
import { sendPasswordReset } from "services/auth-service";
import { PrimaryText } from "components/Common/PrimaryText";

const { height } = Dimensions.get("window");

export function Landing() {
  const [email, setEmail] = useState(""); //jim@tracknicity.com
  const [password, setPassword] = useState(""); //0522MAy!
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const whiteLogo = require("~/assets/whiteLogo.png");

  useEffect(() => {
    setEmailError(null);
    setPasswordError(null);

    //check valid email here
    checkIfLoginActive();
  }, [email, password]);

  useEffect(() => {
    setLoading(false);
  }, [emailError, passwordError]);

  const checkIfLoginActive = () => {
    if (email !== "" && password !== "") {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  const loginPressed = () => {
    setLoading(true);
    login(email, password, setEmailError, setPasswordError);
  };

  function forgotPasswordPressed() {
    const email = window.prompt("Enter your email address");
    if (email) {
      sendPasswordReset(email);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <View style={styles.imageContainer}>
          <Image
            source={whiteLogo}
            style={{ width: height * 0.3, height: height * 0.3 }}
          />
        </View>
        <View style={styles.bodyContainer}>
          <View>
            <form>
              <View style={styles.inputContainer}>
                {/* <label for="current-password">Current Password: </label> */}
                {/* <input
                  type="password"
                  name="password"
                  id="password"
                  autocomplete="password"
                /> */}

                {/* <CustomInput
                title={"Email"}
                placeholder={"Enter email address"}
                value={email}
                errorMessage={emailError}
                onChange={(e) => setEmail(e.toLowerCase())}
                id="username"
                name="ig"
                type="text"
                textContentType={"text"}
              /> */}
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={styles.inputTitle}
                >
                  {"Email"}
                </PrimaryText>
                <View style={styles.input}>
                  <input
                    type="text"
                    id="username"
                    name="ig"
                    style={{
                      height: "100%",
                      color: Colors.primary.black,
                      borderWidth: 0,
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </View>
                {emailError && (
                  <View style={styles.errorContainer}>
                    {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                    <Text style={styles.errorMessage}>{emailError}</Text>
                  </View>
                )}
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={styles.inputTitle}
                >
                  {"Password"}
                </PrimaryText>
                <View style={styles.input}>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    style={{
                      height: "100%",
                      color: Colors.primary.black,
                      borderWidth: 0,
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </View>
                {passwordError && (
                  <View style={styles.errorContainer}>
                    {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                    <Text style={styles.errorMessage}>{passwordError}</Text>
                  </View>
                )}
                {/* <CustomInput
                  title={"Password"}
                  placeholder={"Enter password"}
                  value={password}
                  errorMessage={passwordError}
                  onChange={(e) => setPassword(e)}
                  secure={true}
                  autoCompleteType={"password"}
                  textContentType={"password"}
                /> */}
              </View>
            </form>
            <TouchableOpacity onPress={() => forgotPasswordPressed()}>
              <Text style={styles.forgotPassword}>Forgot Password?</Text>
            </TouchableOpacity>

            <TouchableHighlight
              style={[
                styles.loginButton,
                loginDisabled ? styles.inactive : styles.active,
              ]}
              underlayColor={"#DDD"}
              onPress={() => loginPressed()}
              disabled={loginDisabled}
            >
              <Text style={styles.loginButtonText}>Log In</Text>
            </TouchableHighlight>
          </View>
        </View>
      </View>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    minWidth: 500,
  },
  imageContainer: {
    // flex: 1,
    alignItems: "center",
    marginTop: 20,
  },
  loginText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    color: Colors.primary.lightBlue,
  },
  bodyContainer: {
    flex: 1,
    marginTop: 0,
    paddingHorizontal: 24,
    alignContent: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: 8,
  },
  forgotPassword: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    textAlign: "right",
    color: Colors.primary.lightBlue,
    fontSize: 14,
    fontWeight: "700",
  },
  loginButton: {
    marginTop: 40,
    marginBottom: 44,
    height: 48,
    borderRadius: 15,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.lightBlue,
  },
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0.5,
  },
  loginButtonText: {
    fontSize: 20,
    fontWeight: "600",
    color: Colors.primary.white,
  },
  termsText: {
    textAlign: "center",
    color: Colors.primary.black,
    fontSize: 14,
    fontWeight: "400",
    flexWrap: "nowrap",
  },
  termsButtonText: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: Colors.primary.lightBlue,
    flexWrap: "nowrap",
  },
  inputTitle: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    paddingTop: 12,
    color: Colors.primary.darkGray,
  },

  input: {
    borderRadius: 16,
    height: 40,
    borderColor: "#999999",
    borderWidth: 0.5,
    paddingHorizontal: 16,
    color: Colors.primary.black,
  },
  errorContainer: {
    backgroundColor: "#FEEFEF",
    height: 32,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 8,
    flexDirection: "row",
  },
  errorMessage: {
    color: Colors.primary.error,
    paddingLeft: 8,
  },
});
