import React, {memo} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as MaterialCommunityIcons from "react-icons/md";


const Card = ({
  item,
  onPress,
  highlighted,
  onArchive
}) => {

  return (
    <TouchableOpacity style={[styles.card, highlighted && {backgroundColor: Colors.primary.lightGray}]} onPress={onPress}>
      <PrimaryText fontSize={16} fontWeight={400} style={styles.text}>{item.model}</PrimaryText>
      {item.archived && !!onArchive ?
        <TouchableOpacity style={styles.archiveButton} onPress={onArchive}>
          <MaterialCommunityIcons.MdUnarchive color={Colors.primary.gray} size={24} />
        </TouchableOpacity>
      :
        <View style={{flexDirection:'row', alignItems:'center'}}>
          <PrimaryText fontSize={16} fontWeight={400} style={styles.text}>Qty: {item.qty}</PrimaryText>
          {!!onArchive &&
          <TouchableOpacity style={styles.archiveButton} onPress={onArchive}>
            <MaterialCommunityIcons.MdArchive color={Colors.primary.gray} size={24} />
          </TouchableOpacity>
          }
          
        </View>
      }
      
    </TouchableOpacity>
  )
}

export const ModelCard = memo(Card)

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  archiveButton: {
    padding: 8,
  }

});


