import { auth, firestore } from "utils/firebase";
var engine = require('store/src/store-engine')
var storages = [
	require('store/storages/sessionStorage'),
	require('store/storages/cookieStorage')
]
var plugins = [
	require('store/plugins/defaults'),
	require('store/plugins/expire')
]
var browserStorage = engine.createStore(storages, plugins)

export const login = (email, password, setEmailError, setPasswordError) => {
    auth.signInWithEmailAndPassword(email, password).then(user => {

      }).catch(err => {

        console.log('error signing in', err);
        //return errors

        if (err.code === 'auth/invalid-email') {
            setEmailError('Invalid email address')
        } else {
            setPasswordError('Invalid password')
        }
      })
}

export const logout = (setCurrentUser, clearState) => {
  auth.signOut()
  .then(() => {
    setCurrentUser(null)
    clearState()
    browserStorage.clearAll()
  })

  
}

export const getUser = (companyId, userId, func) => {
  // console.log(companyId, userId)
  firestore.collection('companies').doc(companyId).collection('users').doc(userId)
  .get().then(userDoc => {
    if (userDoc.exists) {
      const user = {
        ...userDoc.data(),
        id: userDoc.id
      }
      func(user)
    } else {
      func(null)
    }
  })
}

export const sendPasswordReset = (email) => {
  auth.sendPasswordResetEmail(email)
  .then(() => {
    window.alert('Password reset email successfully sent')
  })
  .catch(e => {
    window.alert(e.message)
    // console.log('error sending password reset', e)
  })
}