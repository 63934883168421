import React, { useEffect } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Colors } from "constants/Colors";
import { useParts } from "context/PartsContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as AiIcons from "react-icons/ai";
import { HistoryCard } from "components/Cards/HistoryCard";
import { useAuth } from "context/AuthContext";
import { Header } from "components/Header";
import { firestore } from "utils/firebase";
import { useCompany } from "context/CompanyContext";
import { dollarFormatter } from "context/CompanyContext";

const { width, height } = Dimensions.get("window");

export function ItemDetail({ toggleShowLocations, showEditItem }) {
  const { currentUser } = useAuth();
  const { company, isDTools } = useCompany();
  const { selectedItem, itemHistory, locationParts } = useParts();

  // console.log('history', itemHistory)

  // useEffect(() => {
  //     if (selectedItem) {
  //         const items = locationParts.filter(x => x.partID===selectedItem.id)
  //         const sum = items?.length ? items.map(x => x.locationQty).reduce((a,b) => {return a+b}) : 0

  //         if (sum!==selectedItem?.qty) {
  //             // console.log('qty doesnt match', sum)
  //             const docRef = firestore.collection('companies').doc(company.id).collection('parts').doc(selectedItem.id)
  //             docRef.update({qty: sum})
  //         }
  //     }
  // }, [selectedItem])

//   console.log(currentUser);

  const { viewItemCost = true, viewItemPrice = true } = currentUser;

  const name = selectedItem?.manufacturer
    ? `${selectedItem.manufacturer}: ${selectedItem.model}`
    : selectedItem.model;

  return (
    <>
      <View style={styles.container}>
        <Header
          title={name}
          onRight={currentUser?.admin ? showEditItem : null}
        />
        <View style={styles.topContainer}>
          {/** QTY */}
          <View style={styles.qtyContainer}>
            <PrimaryText fontSize={16} fontWeight={400}>
              QTY
            </PrimaryText>
            <PrimaryText fontSize={16} fontWeight={400}>
              {selectedItem?.qty}
            </PrimaryText>
          </View>

          {/** COST */}
          <View style={styles.spacedContainer}>
            <View style={styles.valueContainer}>
              {viewItemCost && (
                <PrimaryText
                  fontSize={16}
                  fontWeight={400}
                  style={styles.label}
                >
                  {`Cost\n${dollarFormatter.format(selectedItem?.cost)}`}
                </PrimaryText>
              )}

              {viewItemCost && viewItemPrice && (
                <View style={styles.verticalBar} />
              )}

              {viewItemPrice && (
                <PrimaryText
                  fontSize={16}
                  fontWeight={400}
                  style={styles.label}
                >
                  {`Price\n${dollarFormatter.format(selectedItem?.price)}`}
                </PrimaryText>
              )}
            </View>
          </View>

          {isDTools && selectedItem?.partNumber && (
            <View style={styles.spacedContainer}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.label}>
                {`Part Number: ${selectedItem?.partNumber}`}
              </PrimaryText>
            </View>
          )}

          {/** UPC */}
          <View
            style={[
              styles.spacedContainer,
              { flexDirection: "row", alignItems: "center" },
            ]}
          >
            <AiIcons.AiOutlineBarcode
              name="barcode"
              color={Colors.primary.white}
              size={24}
              style={{ marginRight: 12 }}
            />
            <PrimaryText fontSize={14} fontWeight={400} style={styles.label}>
              {`UPC: ${selectedItem?.upc ? selectedItem?.upc : "N/A"}`}
            </PrimaryText>
          </View>

          {/** Description */}
          {!!selectedItem?.description && (
            <View style={styles.spacedContainer}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.label}>
                {`Description: ${selectedItem?.description}`}
              </PrimaryText>
            </View>
          )}

          {/** View Locations Button */}
          <View style={styles.spacedContainer}>
            <TouchableOpacity
              onPress={toggleShowLocations}
              style={styles.locationButton}
            >
              <PrimaryText fontSize={14} fontWeight={400} style={styles.label}>
                View Locations
              </PrimaryText>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <PrimaryText
            fontSize={16}
            fontWeight={400}
            style={{ textAlign: "center" }}
          >
            History
          </PrimaryText>
          {itemHistory && itemHistory.length > 0 ? (
            <FlatList
              style={{ flex: 1 }}
              contentContainerStyle={{ backgroundColor: Colors.primary.white }}
              data={itemHistory}
              keyExtractor={(item) => item.id}
              renderItem={({ item, index }) => {
                return <HistoryCard item={item} />;
              }}
            />
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrimaryText fontSize={16} fontWeight={200}>
                No History Available
              </PrimaryText>
            </View>
          )}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.primary.white,
  },
  topContainer: {
    // flex: 1,
    paddingTop: 12,
    backgroundColor: Colors.primary.red,
    justifyContent: "space-between",
    alignItems: "center",
  },
  qtyContainer: {
    height: height * 0.1,
    width: height * 0.1,
    borderRadius: height * 0.5,
    backgroundColor: Colors.primary.white,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 6,
  },
  spacedContainer: {
    marginVertical: 6,
  },
  label: {
    color: Colors.primary.white,
    textAlign: "center",
  },
  valueContainer: {
    flexDirection: "row",
  },
  verticalBar: {
    width: 1,
    backgroundColor: Colors.primary.white,
    height: "100%",
    marginHorizontal: 12,
  },
  locationButton: {
    borderWidth: 1,
    borderColor: Colors.primary.white,
    padding: 6,
    borderRadius: 6,
  },
  bottomModal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    height: height * 0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    alignItems: "center",
  },
});
