import React, { memo } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { useCompany } from "~/context/CompanyContext";
import { getItemName } from "utils/helpers";
import { TouchableOpacity } from "react-native-web";

const Card = ({ item, showItem }) => {
  const { users } = useCompany();

  let style = styles(false);
  const user = users.find((x) => x.id === item.user);
  let color =
    !item?.fromClientId && !item.toClientId && !item.manualAdjustment
      ? Colors.primary.purple //internal
      : item.fromClientId
      ? Colors.primary.green //return
      : Colors.primary.red; //to client

  if (item.manualAdjustment && !item.fromClientId) {
    item.fromLocationId === "manual"
      ? (color = Colors.primary.green)
      : (color = Colors.primary.red);
  }

  if (item.fromLocationId === "audit") color = Colors.primary.green;
  if (item.toLocationId === "audit") color = Colors.primary.red;
  if (item.from === "Staging" || item.to === "Staging") color = "transparent";

  let userName = user ? user?.firstName + " " + user?.lastName : item?.user;

  return (
    <TouchableOpacity style={style.card} onPress={() => console.log(item)}>
      <View style={{ flex: 1 }}>
        <PrimaryText fontSize={16} fontWeight={500}>
          {userName}
        </PrimaryText>
        {showItem && (
          <PrimaryText
            fontSize={13}
            fontWeight={500}
            style={{ flex: 1 }}
            numberOfLines={1}
          >
            Item: {getItemName(item)}
          </PrimaryText>
        )}
        {item?.toLocationId !== "manual" && item?.toLocationId !== "audit" ? (
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={{ flex: 1 }}
            numberOfLines={1}
          >{`${
            item?.fromLocationId !== "manual" &&
            item?.fromClientId !== "received" &&
            item?.fromLocationId !== "audit"
              ? "From: "
              : ""
          }${item?.from}`}</PrimaryText>
        ) : (
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={{ flex: 1 }}
            numberOfLines={1}
          >
            {item?.to}
          </PrimaryText>
        )}
        {item?.toLocationId !== "manual" && item?.toLocationId !== "audit" ? (
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={{ flex: 1 }}
            numberOfLines={1}
          >
            To: {item?.to}
          </PrimaryText>
        ) : (
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={{ flex: 1 }}
            numberOfLines={1}
          >{`${
            item?.toLocationId !== "manual" && item?.toLocationId !== "audit"
              ? "To: "
              : "Out of: "
          }${item?.from}`}</PrimaryText>
        )}

        <PrimaryText fontSize={13} fontWeight={400}>
          Date: {item?.date.toDate().toDateString()}
        </PrimaryText>
      </View>
      <View style={[style.qtyContainer, { backgroundColor: color }]}>
        <PrimaryText
          fontSize={16}
          fontWeight={400}
          style={{
            color:
              color === "transparent"
                ? Colors.primary.darkGray
                : Colors.primary.white,
          }}
        >
          {item?.qty}
        </PrimaryText>
      </View>

      {item.rejected && (
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.6)",
          }}
        >
          <PrimaryText
            fontSize={24}
            fontWeight={400}
            style={{ color: Colors.primary.red }}
          >
            Rejected
          </PrimaryText>
        </View>
      )}

      {!item.rejected && item?.projectAddition && (
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0)",
          }}
        >
          <PrimaryText
            fontSize={24}
            fontWeight={400}
            style={{ color: Colors.primary.green }}
          >
            Project {color === Colors.primary.green ? "Return" : `Addition`}
          </PrimaryText>
        </View>
      )}
    </TouchableOpacity>
  );
};

export const HistoryCard = memo(Card);

export const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      flex: 1,
      marginHorizontal: 12,
      paddingVertical: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
    },
    qtyContainer: {
      height: 40,
      width: 40,
      borderRadius: 20,
      justifyContent: "center",
      alignItems: "center",
    },
  });
