import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  Alert,
  ActivityIndicator,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
} from "react-native";
import * as VSIcons from "react-icons/vsc";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import cloneDeep from "lodash/cloneDeep";
import firebase from "firebase/app";

import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { PrimaryText } from "components/Common/PrimaryText";
import { ManufacturerCard } from "components/Cards/ManufacturerCard";
import { ModelCard } from "components/Cards/ModelCard";
import { CustomInput } from "components/Inputs/CustomInput";
import { ItemCheckoutCard } from "components/Cards/ItemCheckoutCard";

import { useCompany } from "context/CompanyContext";
import { useParts } from "context/PartsContext";
import { useAuth } from "context/AuthContext";

import { isParentItem, searchParts, selectPart } from "utils/helpers";
import { FullModal } from "components/Modals/FullModal";
import { ListContainer } from "components/ListContainer";
import { Scanner } from "components/Scanner/Scanner";
import { BiBarcode } from "react-icons/bi";

const { width, height } = Dimensions.get("window");

export function ReceiveTransaction() {
  const { company, selectedLocation } = useCompany();
  const { currentUser } = useAuth();
  const { manufacturers, parts, locationParts } = useParts();

  //items
  const [items, setItems] = useState(manufacturers);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [search, setSearch] = useState(null);
  const [showBrowseModal, setShowBrowseModal] = useState(false);

  //other
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");

  useEffect(() => {
    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    setSubmitDisabled(!(selectedItems && selectedItems.length > 0));
  }, [selectedItems]);

  /** START ITEMS */

  //reset items on close browse modal
  useEffect(() => {
    !showBrowseModal && setItems(manufacturers);
  }, [showBrowseModal]);

  //search or browse parts
  useEffect(() => {
    searchParts(
      search,
      selectedParent,
      items,
      setItems,
      parts,
      manufacturers,
      showBrowseModal
    );
  }, [search]);

  useEffect(() => {
    if (!selectedParent) {
      setItems(manufacturers);
      setSearch(null);
    }
  }, [selectedParent]);

  function itemSelected(item) {
    selectPart(
      item,
      parts,
      setSelectedParent,
      setItems,
      setSelectedItems,
      setSearch,
      setShowBrowseModal,
      true,
      selectedItems
    );
  }

  function itemQtyChanged(item, index) {
    const temp = cloneDeep(selectedItems);
    temp[index] = item;
    setSelectedItems(temp);
  }

  function removeItem(index) {
    const temp = cloneDeep(selectedItems);
    temp.splice(index, 1);
    setSelectedItems(temp);
  }
  /** END ITEMS */

  function submitTransaction() {
    setSubmitDisabled(true);
    setLoading(true);

    const batch = firebase.firestore().batch();
    //PROMISE?
    selectedItems.forEach((item) => {
      const history = {
        manufacturer: item.manufacturer,
        model: item.model,
        from: "Received To Location",
        fromLocationId: "",
        fromClientId: "received",
        to: selectedLocation.name,
        toLocationId: selectedLocation.id,
        toClientId: "",
        date: new Date(),
        qty: item.qty,
        user: currentUser.id,
        received: false,
        approved: false,
        partID: item.id,
        custom: false,
        rejected: false,
      };

      //addHistory() ?
      const historyDocRef = firebase
        .firestore()
        .collection("companies")
        .doc(company.id)
        .collection("history")
        .doc();
      batch.set(historyDocRef, history);

      updateQuantity(history);
      updateLocationQuantity(item, history);
    });

    batch
      .commit()
      .then(() => {
        window.alert("Transaction Successful");
        clearAll();
      })
      .catch((e) => {
        setLoading(false);
        console.log("error committing history batch", e);
      });
  }

  function updateQuantity(item) {
    const partRef = firebase
      .firestore()
      .collection("companies")
      .doc(company.id)
      .collection("parts")
      .doc(item.partID);
    firebase
      .firestore()
      .runTransaction(async (transaction) => {
        const snapshot = await transaction.get(partRef);
        const partData = snapshot.data();
        const { qty, cost } = partData;
        const oldQty = qty || 0;
        const newQty = oldQty + item.qty;
        const totalValue = cost * newQty;
        transaction.update(partRef, {
          qty: newQty,
          totalValue,
          lastModified: new Date(),
        });
      })
      .catch((e) => {
        console.log("error updateQuantity transaction", e, item.partID);
      });
  }

  function updateLocationQuantity(part, history) {
    const locationID = selectedLocation.id;
    const locationName = selectedLocation.name;
    const locationPartId = `${locationID}-${part.id}`;
    const exists = locationParts.find(
      (x) => x.locationPartId === locationPartId
    );
    const locationPartRef = firebase
      .firestore()
      .collection("companies")
      .doc(company.id)
      .collection("locationParts")
      .doc(locationPartId);

    if (exists) {
      locationPartRef.update({
        qty: firebase.firestore.FieldValue.increment(history.qty),
      });
    } else {
      const locationPart = {
        locationID,
        locationName,
        lowercaseMfg: part.lowercaseMfg,
        lowercaseModel: part.lowercaseModel,
        minStockQty: 0,
        partID: part.id,
        qty: history.qty,
      };
      locationPartRef.set(locationPart);
    }
  }

  function clearAll() {
    setSelectedItems([]);
    setLoading(false);
  }

  function RenderItem({ index, style }) {
    const item = items[index];
    if (!item.active || item.archived) return null;
    if (isParentItem(parts, item)) {
      return (
        <View style={style}>
          <ManufacturerCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    } else {
      return (
        <View style={style}>
          <ModelCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    }
  }

  return (
    <>
      <View style={styles.container}>
        <Header
          title={`Receive to ${selectedLocation.name}`}
          onRight={() => submitTransaction()}
          rightText={"Submit"}
          rightDisabled={submitDisabled}
        />

        <View style={styles.topContainer}>
          {/** Buttons */}
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={() => setShowBrowseModal("search")}
              style={styles.itemButtons}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.itemButtonText}
              >
                {"Search Items"}
              </PrimaryText>
              <VSIcons.VscSearch
                name="magnify"
                color={Colors.primary.white}
                size={20}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setShowScanner(true)}
              style={styles.itemButtons}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.itemButtonText}
              >
                {"Scan Items"}
              </PrimaryText>
              <BiBarcode
                name="magnify"
                color={Colors.primary.white}
                size={20}
              />
            </TouchableOpacity>
          </View>
        </View>

        {selectedItems && selectedItems.length > 0 ? (
          <FlatList
            data={selectedItems}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => {
              return (
                <ItemCheckoutCard
                  onQuantityChanged={(a, b) => itemQtyChanged(a, b)}
                  item={item}
                  removeItem={() => removeItem(index)}
                />
              );
            }}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <PrimaryText fontSize={16} fontWeight={400}>
              No Items Selected
            </PrimaryText>
          </View>
        )}
      </View>

      {/** BROWSE MODEL */}
      {!!showBrowseModal && (
        <FullModal>
          <View style={styles.modalContent}>
            <View style={styles.modalSwipeButton} />
            <View style={styles.modalHeader}>
              {selectedParent ? (
                <TouchableHighlight
                  underlayColor={"transparent"}
                  style={{ width: 40 }}
                  onPress={() => setSelectedParent(null)}
                >
                  <FaIcons.FaChevronLeft
                    name="chevron-left"
                    color={Colors.primary.darkGray}
                    size={24}
                  />
                </TouchableHighlight>
              ) : (
                <PrimaryText
                  fontSize={20}
                  fontWeight={400}
                  style={{ width: 40 }}
                ></PrimaryText>
              )}

              <PrimaryText fontSize={18} fontWeight={400}>
                {selectedParent
                  ? parts.find((x) => x.id === selectedParent.id).model
                  : "Items"}
              </PrimaryText>

              <TouchableOpacity onPress={() => setShowBrowseModal(false)}>
                <AiIcons.AiOutlineClose
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>

            <CustomInput
              placeholder={"Search Items"}
              style={{ width: "90%", marginVertical: 4 }}
              onChange={setSearch}
              value={search}
            />

            <ListContainer
              items={items}
              size={40}
              renderItem={RenderItem}
              listWidth={400}
            />
          </View>
        </FullModal>
      )}

      <Scanner
        // editing={true}
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        scannedItem={scannedItem}
        setScannedItem={setScannedItem}
        title={"Scan To Search"}
        setSelectedItem={itemSelected}
        // setUpc={setUpc}
      />

      {loading && (
        <ActivityIndicator
          size={"large"}
          animating
          color={Colors.primary.blue}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: "rgba(0,0,0,0.3)" },
          ]}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    marginBottom: 12,
  },
  locationContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  locationButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
    marginRight: "10%",
    borderBottomWidth: 1,
    borderBottomColor: Colors.primary.lightGray,
    paddingVertical: 4,
  },
  buttonContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  itemButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.red,
    borderRadius: 4,
    paddingVertical: 6,
    marginHorizontal: 4,
    width: "30%",
  },
  itemButtonText: {
    color: Colors.primary.white,
    marginRight: 6,
  },
  extraButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: Colors.primary.darkGray,
    borderWidth: 1,
    padding: 6,
    marginHorizontal: "20%",
    marginVertical: 12,
  },

  hiddenIconsContainer: {
    width: width * 0.175,
    marginRight: 12,
    flexDirection: "row",
    position: "absolute",
    right: 0,
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
  },
  hiddenButton: {
    width: width * 0.15,
    height: "100%",
    borderRadius: height * 0.01,
    alignItems: "center",
    justifyContent: "center",
  },
  hiddenIcons: {
    width: "50%",
    height: "50%",
    resizeMode: "contain",
  },

  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    height: height * 0.7,
    backgroundColor: Colors.primary.white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: 12,
    alignItems: "center",
  },
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
    marginBottom: height * 0.3,
  },
  modalHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  customModalContent: {
    // height: height*0.6,
    width: 400,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  customButton: {
    marginTop: 36,
    marginHorizontal: 12,
    height: 30,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,

    backgroundColor: Colors.primary.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});
