import React, { useEffect, useState } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import {Colors} from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { isParentItem } from "utils/helpers";
import cloneDeep from "lodash/cloneDeep";
import { Header } from "components/Header";
import { SelectManufacturerCard } from "components/Cards/SelectManufacturerCard";
import { SelectModelCard } from "components/Cards/SelectModelCard";


export function SelectItems({onSelectPressed, selectedItems, onRight, rightText, rightDisabled}) {

    const {manufacturers, parts, setSelectedItem, selectedItem} = useParts()

    const [items, setItems] = useState([])
    const [search, setSearch] = useState(null)
    const [selectedParent, setSelectedParent] = useState(null)

    
    useEffect(() => {

        if (search) {
        if (selectedParent) {
            const temp = items.filter(x => x.active 
                && !x.archived 
                && (x.lowercaseModel.includes(search.toLowerCase()) 
                // || x.lowercaseMfg.includes(search.toLowerCase())
                )
                )
            setItems(temp)
        } else {
            const temp = parts.filter(x => x.active 
            && !x.archived 
            && (x.lowercaseModel.includes(search.toLowerCase()) 
                // || x.lowercaseMfg.includes(search.toLowerCase())
                )
            )
            setItems(temp)
        }
        
        } else {
            if (selectedParent) {
                const temp = parts.filter(x => x.active && !x.archived && x.parentRefID===selectedParent.id)
                setItems(temp)
            } else {
                manufacturers && setItems(manufacturers.filter(x => x.active && !x.archived))
            }
        }

        if (selectedItem) {
            const item = parts.find(x => x.id===selectedItem.id)
            setSelectedItem(item)
        }

    }, [search, selectedParent, parts, manufacturers])


    useEffect(() => {
        if (!selectedParent) {
            manufacturers && setItems(manufacturers.filter(x => x.active && !x.archived))
        setSearch(null)
        } else {
        setSearch(null)
        }
    }, [selectedParent, parts, manufacturers])

    function itemSelected(item) {

        const clonedItem = cloneDeep(item)
        if (isParentItem(parts, clonedItem)) {
            clonedItem.timeStamp = new Date().getTime().toString()
            
            const temp = parts.filter(x => x.parentRefID===clonedItem.id)
            setSelectedParent(temp ? clonedItem : null)
            setItems(temp ? temp : clonedItem)
        } else {
            setSelectedItem(clonedItem)
        }
    }

    return (
        <View style={styles.listContainer}>
        <Header 
        title={selectedParent ? selectedParent?.model : 'Items'}
        onBack={selectedParent ? () => setSelectedParent(null) : null}
        onRight={onRight}
        rightText={rightText}
        rightDisabled={rightDisabled}
        />
        <View style={{zIndex: 999, flexDirection: 'row', width: '100%', alignItems:'center'}}>
            <CustomInput 
            placeholder={'Search Inventory Items'}
            style={{marginRight: 12, flex: 1}}
            onChange={setSearch}
            value={search}
            />
        </View>

        <FlatList
            style={{flex: 1}}
            contentContainerStyle={{backgroundColor: Colors.primary.white}}
            data={items}
            keyExtractor={item => item.id}
            renderItem={({ item, index }) => {
                if (isParentItem(parts, item)) {
                return (
                    <SelectManufacturerCard
                    onPress={() => itemSelected(item)}
                    item={item}
                    selected={selectedItems.find(x => x.id===item.id)}
                    onSelect={() => onSelectPressed(item)}
                    />
                )
                } else {
                return (
                    <SelectModelCard
                    onPress={() => itemSelected(item)}
                    item={item}
                    selected={selectedItems.find(x => x.id===item.id)}
                    onSelect={() => onSelectPressed(item)}
                    />
                )
                }
                
            }}
        />
        </View>
    );
}

const styles = StyleSheet.create({
    listContainer: {
        flex: 1,
        // width: '100%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    },
    backContainer: {
      marginVertical: 8,
      paddingHorizontal: 12,
      flexDirection: 'row',
      alignItems:'center'
    },
    optionContainer: {
      // paddingHorizontal: 12,
      // width: 300,
      flexDirection: 'row',
      alignItems:'center',
      // justifyContent:'space-between',
      marginVertical: 12,
      marginRight: 4
    },
});
