import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { useParts } from "context/PartsContext";
import { MinMaxCard } from "components/Cards/MinMaxCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { formatMinMaxReport } from 'utils/exports-helper';
import { getItemNameFromManufacturer } from "utils/helpers";
import { firestore } from "utils/firebase";


export function MinMaxReport() {

  const navigate = useNavigate()

  const { minMaxParts, setSelectedItem, manufacturers } = useParts()
  const { locations, isDTools, company } = useCompany()

  const [items, setItems] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
      if (minMaxParts && locations) {
          setLoading(true)

          const temp = minMaxParts
          temp.forEach(item => {
              const locationName = locations.find(x => x.id===item.locationID)?.name
              item.locationName = locationName
          })
          temp.sort((a, b) => a.lowercaseMfg > b.lowercaseMfg && a.lowercaseModel > b.lowercaseModel ? 1 : -1)
          setItems(temp)
      } else {
        setLoading(false)
      }

  }, [minMaxParts])

  useEffect(() => {
    if (loading && items) {
      setFiltered(items)
      setLoading(false)
    }
  }, [items])

  useEffect(() => {
    if (search) {
      const temp = items
      .filter(x => 
        x.locationName.toLowerCase().includes(search.toLowerCase())
      || x.model.toLowerCase().includes(search.toLowerCase())
      || x.manufacturer.toLowerCase().includes(search.toLowerCase())
      )

      setFiltered(temp)
    } else {
      setFiltered(items)
    }
  }, [search])

  useEffect(() => {
    if (isDTools || !filtered) return
    let subs = []
    filtered.forEach(item => {
      // item.onOrderQty = 0
      let sub = firestore.collection('companies').doc(company.id).collection('purchaseOrders')
      .where('itemListId', '==', item.id)
      .where('customerId', '==', '')
      .where('receivedInFull', '==', false)
      .onSnapshot(querySnapshot => {
        // console.log(querySnapshot.size, item.id)
        let total = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          total += data.quantity;
        })
        item.onOrderQty = total
      }, err => {
        console.log(err)
      })
      subs.push(sub)
    })

    return () => {
      subs.forEach(sub => sub())
    }

  }, [filtered])

  function itemSelected(item) {
    setSelectedItem(item)
    navigate(Links.Inventory)
  }


  return (
    <View style={styles.container}>
        <Header 
        title={'Min/Max Report'}
        exportData={formatMinMaxReport(filtered)}
        />
        {loading ?
            <ActivityIndicator animating size={'large'} style={styles.activity} color={Colors.primary.lightBlue} />
        :
            <>
                <CustomInput 
                placeholder={'Filter By Item or Location'}
                style={{marginHorizontal: 12}}
                onChange={setSearch}
                value={search}
                />
                <View style={styles.detailLabelContainer}>
                    <View style={styles.leftContainer}>
                        <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1}} ></PrimaryText>
                        <PrimaryText fontSize={12} fontWeight={400} style={{flex: 1}} ></PrimaryText>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Qty</PrimaryText>
                        <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Min</PrimaryText>
                        <PrimaryText fontSize={13} fontWeight={400} style={styles.text}>Max</PrimaryText>
                        {!isDTools && <PrimaryText fontSize={13} fontWeight={400} style={[styles.text, {width: 90}]}>On Order</PrimaryText>}
                    </View>
                </View>
                {!loading && !filtered ?
                <View style={{flex: 1, justifyContent:'center', alignItems:'center'}}>
                  <PrimaryText fontSize={20} fontWeight={400}>No items to report</PrimaryText>
                </View>
                :
                <FlatList
                style={{flex: 1}}
                contentContainerStyle={{backgroundColor: Colors.primary.white}}
                data={filtered}
                keyExtractor={item => item.locationPartId}
                renderItem={({ item, index }) => {
                    return (
                        <MinMaxCard
                        item={item}
                        name={getItemNameFromManufacturer(item, manufacturers)}
                        location={item?.locationName}
                        showItem
                        onPress={() => itemSelected(item)}
                        />
                    )
                }}
                />
                }
                
            </>
        }
        
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    },
    activity: {
      flex: 1,
      backgroundColor: Colors.primary.white
    },
    detailLabelContainer: {
        width: '100%',
        flexDirection:'row',
        justifyContent:'space-between',
        paddingHorizontal: 12,
        marginTop: 12,
        backgroundColor: Colors.primary.gray
    },
    leftContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    text: {
        width: 60,
        textAlign:'right',
        color: Colors.primary.white
    },

});
