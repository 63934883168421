import React, { forwardRef } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
  Text,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";

const { height, width } = Dimensions.get("window");

export const OpenPurchaseOrderCard = ({
  item,
  onPress,
  admin,
  onDelete,
  open = true,
  onPrint = false,
  selectedCustomer,
  onEdit,
}) => {
  let style = styles(false);

  // console.log(item?.ProjectSIItemIds)

  const {
    returnedQuantity = 0,
    quantity = 0,
    receivedQuantity = 0,
    deliveredQuantity = 0,
    itemFullName,
    customerFullName = ""
  } = item

  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        style={[
          style.card,
          quantity !== deliveredQuantity && {
            backgroundColor: Colors.primary.lightGray,
            borderColor: Colors.primary.white,
          },
        ]}
      >
        <View style={style.leftContainer}>
          <PrimaryText fontSize={13} fontWeight={500} style={{ flex: 1 }} >{itemFullName}</PrimaryText>
          <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1 }} >{customerFullName}</PrimaryText>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={style.text}
          >
            {quantity - returnedQuantity}
          </PrimaryText>
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={style.text}
          >
            {receivedQuantity - returnedQuantity}
          </PrimaryText>
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={style.text}
          >
            {deliveredQuantity - returnedQuantity}
          </PrimaryText>
          <PrimaryText
            fontSize={13}
            fontWeight={400}
            style={style.text}
          >
            {item?.poNumber}
          </PrimaryText>
          {admin && (
            <View style={style.deleteButtonContainer}>
              <TouchableOpacity onPress={onDelete} style={style.deleteButton}>
                <FaIcons.FaTrashAlt size={14} color={Colors.primary.white} />
              </TouchableOpacity>
            </View>
          )}
        </View>


      </TouchableOpacity>
    </>
  );
};

const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      // width: "100%",
      paddingHorizontal: 12,
      paddingVertical: 8,
      // marginHorizontal: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
      // backgroundColor: Colors.primary.red
    },
    text: {
      width: width * 0.06,
      textAlign: 'center',
    },
    deleteButtonContainer: {
      width: width * 0.03,
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: Colors.primary.red,
      padding: 8,
      borderRadius: 4,
    },
    editButton: {
      backgroundColor: Colors.primary.gray,
      padding: 8,
      borderRadius: 4,
    },
    printButtonContainer: {
      width: width * 0.03,
      alignItems: "center",
    },
    printButton: {
      backgroundColor: Colors.primary.green,
      padding: 8,
      borderRadius: 4,
    },
    leftContainer: {
      flex: 1,
    },
    deleteButtonContainer: {
      width: width * 0.03,
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: Colors.primary.red,
      padding: 8,
      borderRadius: 4,
    },
  });
