import React, { forwardRef, useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator, FlatList } from "react-native";
import DatePicker from "react-datepicker";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { Fonts } from "constants/Fonts";
import "react-datepicker/dist/react-datepicker.css";
import { getDeliveredItemsByDate, getDeliveredStockItemsByDate } from "services/company-service";
import { DeliveredItemCard } from "components/Cards/DeliveredItemCard";
import { useCustomers } from "context/CustomersContext";
import * as FaIcons from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { formatDeliveryReport } from "utils/exports-helper";
import { useParts } from "context/PartsContext";

const { width, height } = Dimensions.get('window');

export function DeliveredItems() {

  const navigate = useNavigate()

  const { users, company } = useCompany()
  const { customers } = useCustomers()
  const { parts, manufacturers } = useParts()

  const [search, setSearch] = useState(null)

  const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
  const [historyItems, setHistoryItems] = useState([])
  const [allItems, setAllItems] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let start = new Date()
    start.setDate(start.getDate() - 7)
    setStartDate(start)
  }, [])

  useEffect(() => {
    setLoading(true);

    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const poSubscriber = getDeliveredItemsByDate(company, startDate, endDate)
      .onSnapshot(querySnapshot => {
        const temp = []
        querySnapshot.forEach(doc => {
          const history = {
            id: doc.id,
            ...doc.data()
          }

          if (!history.customerId) return

          const user = users.find(x => x.id === history.deliveredBy)
          const userName = user ? user.firstName + ' ' + user.lastName : 'na'
          history.userName = userName

          const customer = customers.find(x => x.id === history.customerId)
          if (customer) history.customerFullName = customer.fullName
          else if (!history.customerFullName) history.customerFullName = 'deleted customer'

          const part = parts.find(x => x.id === history.itemListId)
          if (part) {
            if (part.parentRefID) {
              const parent = parts.find(x => x.id === part.parentRefID)
              part.manufacturer = parent?.model
            }
            history.itemFullName = part.manufacturer ? part.manufacturer + ': ' + part.model : part.model
            history.cost = part.cost
            history.price = part.price
            history.part = part
          } else if (!history.itemFullName) history.itemFullName = 'deleted item'

          const { returnedQuantity = 0 } = history;
          if (history.deliveredQuantity - returnedQuantity <= 0) return
          if (history.deliveredQuantity > 0 && history.price!==undefined) {
            temp.push(history)
          }

        })

        setPurchaseOrderItems(temp)

        setLoading(false)

      })

    const historySubscriber = getDeliveredStockItemsByDate(company, startDate, endDate)
      .onSnapshot(querySnapshot => {
        const temp = []
        querySnapshot.forEach(doc => {
          const history = {
            id: doc.id,
            ...doc.data()
          }

          history.itemFullName = history.manufacturer ? history.manufacturer + ': ' + history?.model : history?.model
          const part = parts.find(x => x.id === history.partID)
          if (part) {
            if (part.parentRefID) {
              const parent = parts.find(x => x.id === part.parentRefID)
              part.manufacturer = parent?.model
            }
            history.itemFullName = part.manufacturer ? part.manufacturer + ': ' + part.model : part.model
            history.cost = part.cost
            history.price = part.price
            history.part = part
          } else if (!history.itemFullName) history.itemFullName = 'deleted item'

          history.customerFullName = history.to

          const customer = customers.find(x => x.id === history.toClientId)
          if (customer) history.customerFullName = customer.fullName
          else if (!history.customerFullName) history.customerFullName = 'deleted customer'

          history.deliveredQuantity = history.qty
          history.deliveredDate = history.date
          history.fromStock = true
          const user = users.find(x => x.id === history.user)
          const userName = user ? user.firstName + ' ' + user.lastName : 'na'
          history.userName = userName

          if (history.toClientId &&
            history.toLocationId !== 'manual' &&
            history.fromLocationId !== 'staging' &&
            !history.rejected && 
            !history.staged
          ) {
            // console.log('test', history)
            temp.push(history)
          }

        })

        setHistoryItems(temp)

        // setLoading(false)

      })

    return () => {
      poSubscriber()
      historySubscriber()
    }

  }, [startDate, endDate])

  useEffect(() => {
    const temp = historyItems.concat(purchaseOrderItems)
    temp.sort((a, b) => a.deliveredDate < b.deliveredDate ? 1 : -1)
    setAllItems(temp)
  }, [historyItems, purchaseOrderItems])

  //on search change
  useEffect(() => {
    if (search) {
      const temp = allItems
        .filter(x =>
          x.itemFullName.toLowerCase().includes(search.toLowerCase())
          || x.customerFullName.toLowerCase().includes(search.toLowerCase())
        )

      setFiltered(temp)
    } else {
      setFiltered(allItems)
    }

  }, [search, allItems])

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input"
      style={{
        backgroundColor: Colors.primary.white,
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: Colors.primary.lightGray,
        fontFamily: Fonts.primary.regular,
        fontSize: 14
      }}
      onClick={onClick} ref={ref}>
      {value}
    </button>
  ))

  return (
    <View style={styles.container}>
      <Header
        title={'Delivered Items'}
        onBack={() => navigate(-1)}
        exportData={formatDeliveryReport(filtered, users)}
      />
      <View style={{ zIndex: 999, flexDirection: 'row', width: '100%', alignItems: 'center' }}>
        <CustomInput
          placeholder={'Filter By Customer or Item'}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />

        <View style={styles.optionContainer} >
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
            numberOfLines={1} >
            Date Range:
          </PrimaryText>

          <View >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<DateInput />}
            />
          </View>

          <PrimaryText fontSize={14} fontWeight={400} style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }} >-</PrimaryText>

          <View >
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<DateInput />}
            />
          </View>

        </View>




      </View>

      <View style={styles.legend}>

        <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 12 }}>
          <FaIcons.FaSquare name="square-medium" color={Colors.primary.red} size={12} style={{ marginRight: 4 }} />
          <PrimaryText fontSize={16} fontWeight={400}>
            {'From Stock'}
          </PrimaryText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FaIcons.FaSquare name="square-medium" color={Colors.primary.blue} size={12} style={{ marginRight: 4 }} />
          <PrimaryText fontSize={16} fontWeight={400}>
            {'From Staging'}
          </PrimaryText>
        </View>

      </View>


      {filtered && filtered?.length > 0 ?
        <View style={styles.body}>
          <View style={styles.detailLabelContainer}>
            <PrimaryText fontSize={14} fontWeight={400} style={{ flex: 1, color: Colors.primary.white }} >Item/Customer</PrimaryText>
            <View style={{ flexDirection: 'row' }}>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.12 }]} >Date</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.12 }]} >By</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Qty</PrimaryText>
              {/* <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Undo</PrimaryText> */}
              {/* <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >From Stock</PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >From Staging</PrimaryText> */}
            </View>
          </View>

          <FlatList
            style={{ flex: 1, width: '100%', borderTopWidth: 1, borderColor: Colors.primary.lightGray }}
            data={filtered}
            keyExtractor={(item, index) => { item.id || index.toString() }}
            renderItem={({ item, index }) => {

              return (
                <DeliveredItemCard
                  item={item}
                  onPress={() => console.log('pressed', item)}
                />
              )
            }}
          />

        </View>
        :
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400} >
            {`No delivered items in selected time range`}
          </PrimaryText>
        </View>
      }

      {loading &&
        <ActivityIndicator
          animating={loading}
          size={'large'}
          style={StyleSheet.absoluteFill} color={Colors.primary.blue}
        />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: 'center'
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent:'space-between',
    marginVertical: 12
  },
  legend: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
    width: width * 0.06,
    textAlign: 'center',
    color: Colors.primary.white
  },


  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: 'center'
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  }


});
